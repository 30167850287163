export const colors = {
  publications: "#0AAACD",
  citations: "#95E0F0",
  trials: "#AEFFDE",
  congresses: "#4E50A7",
  payments: "#7061FF",
  grants: "#FFAC9A",
  collaborators: "#060464",
  socialMediaMentions: "#F5A623"
};

export type ColorKey = keyof typeof colors;
