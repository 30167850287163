import { TagInterface } from "@h1eng/interfaces";
import { ENDPOINTS } from "./api-endpoints";

export async function fetchTagsForPerson(
  personId: string,
  opts?: Partial<RequestInit>
): Promise<{ personId: string; tags: TagInterface[] }> {
  return fetch(`${ENDPOINTS.tags.getTagsForPerson}/${personId}`, {
    mode: "cors",
    credentials: "include",
    ...opts
  }).then(res => res.json());
}

export async function fetchTagsForPeople(
  personIds: string[],
  opts?: Partial<RequestInit>
) {
  return fetch(ENDPOINTS.tags.getTagsForPeople, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ personIds }),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    ...opts
  }).then(res => res.json());
}

export async function fetchTagOptions(): Promise<
  { id: string; name: string; isH1: boolean }[]
> {
  return fetch(ENDPOINTS.tags.options, {
    mode: "cors",
    credentials: "include"
  }).then(res => res.json());
}

export async function createAndAddPersonTag({
  personId,
  name
}: {
  personId: string;
  name: string;
}) {
  return fetch(ENDPOINTS.tags.createAndAddTagToPerson, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ personId, name }),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  }).then(res => res.json());
}

export async function addPersonTag({
  personId,
  tagId
}: {
  personId: string;
  tagId: string;
}) {
  return fetch(ENDPOINTS.tags.addTagToPerson, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ personId, tagId }),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  }).then(res => res.json());
}

export async function removePersonTag({
  personId,
  tagId
}: {
  personId: string;
  tagId: string;
}) {
  return fetch(ENDPOINTS.tags.removeTagFromPerson, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ personId, tagId }),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  }).then(res => res.json());
}

export async function bulkAddTags(
  payload:
    | { personIds: string[]; tagIds: string[] }
    | { personIds: string[]; tagName: string }
) {
  return fetch(ENDPOINTS.tags.bulkAdd, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  }).then(res => res.json());
}

export async function bulkRemoveTags(payload: {
  personIds: string[];
  tagIds: string[];
}) {
  return fetch(ENDPOINTS.tags.bulkRemove, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  }).then(res => res.json());
}
