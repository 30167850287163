/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import {
  PersonCard,
  SortBy,
  SearchView
} from "@h1eng/interfaces";
import * as _ from "lodash";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
  getListForUser,
  getListOptions,
  setSortBy,
  setKOLExportIds
} from "../../../store/actions";
import { userSetSearchView } from "../../../store/actions/userInteractionActions";
import { withRouter, RouteComponentProps } from "react-router";
import {
  currentUser,
  getSelectedProject,
  getLists,
  getAvailableLists,
  getSelectedListId,
  isLoadingPeople,
  getSearchBarState,
  getHasQuery,
  getTotalHits,
  getSortBy,
  getSearchView,
  getIsSearchLoading,
  getTotalAppliedFiltersCount
} from "../../../store/selectors";
import { UserVO } from "../../../models/UserVO";
import { ListMeta } from "../ListManagement/ListManagementInterfaces";


import { styled } from "@h1eng/ui-components";


import {
  Filters,
  FilterCard,
  FILTER_CARD_WIDTH,
  FILTER_CARD_RIGHT_MARGIN
} from "../Filters";
import { LoadingState } from "../KOLResult/Loading";
import { ViewSelector } from "../KOLResult/ViewSelector";
import { StatsView } from "./stats";
import { NoActiveQuery } from "../KOLResult/ResultCards/NoActiveQuery";

interface ResultCardsProps {
  nullState: React.ReactElement<any>;
}

interface ResultCardsState {
  selectAll: boolean;
  selected: string[];

}

interface MappedStateProps {
  user: UserVO;
  projectId: string;
  searchBarState: { query: string[] };
  isLoadingPeople: boolean;
  listsAvailable: ListMeta[];
  lists: {
    [key: string]: {
      personLists: ListMeta[];
    };
  };
  hasQuery: boolean;
  selectedListId: string | null;
  persons: PersonCard[];
  cardsLoading: boolean;
  totalHits: number;
  getSortBy: SortBy;
  searchView: SearchView;
  checkboxDisabled: boolean;
  getTotalAppliedFiltersCount: number;
  pageNum: number;
  pageSize: number;
  selectedKols: string[];
  allPeopleIds: string[];
  bulkIdsLoading: boolean;
}

interface DispatchProps {
  setSearchView: (s: SearchView) => void;
  getList: (opts: {
    projectId: string;
    userId: string;
    personIds: string[];
  }) => void;
  getListOption: (opts: { projectId: string; userId: string }) => void;
  setSortBy: (sortBy: SortBy) => void;
  setSelectedKols: (kols: string[]) => void;
}

type Props = MappedStateProps &
  DispatchProps &
  ResultCardsProps &
  RouteComponentProps;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  margin-left: ${FILTER_CARD_WIDTH + FILTER_CARD_RIGHT_MARGIN}px;
  flex-wrap: wrap;
`;

const ContentRoot = styled.div`
  display: flex;
  flex-direction: row;
`;

class ResultCardsClass extends React.Component<Props, ResultCardsState> {
  state: ResultCardsState = {
    selectAll: false,
    selected: []
  };

  get selectAllIndeterminateState(): boolean {
    return (
      this.state.selected.length > 0 &&
      this.state.selected.length < this.props.persons.length
    );
  }

  componentWillMount() {
    this.props.getListOption({
      projectId: this.props.projectId,
      userId: this.props.user.id
    });

    this.props.getList({
      projectId: this.props.projectId,
      userId: this.props.user.id,
      personIds: this.props.persons.map(e => e.personId)
    });

    this.props.history.listen((location: any, action: any) => {
      this.setState({ selected: [], selectAll: false });
    });
  }

  componentWillReceiveProps(newProps: Props) {
    if (
      this.props.checkboxDisabled === false &&
      newProps.checkboxDisabled === true
    ) {
      this.setState({ selectAll: false, selected: [] });
    }
  }

  /**
   * Handles the "Select All" logic
   */
  handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let selected: string[];
    let selectAll: boolean;

    if (checked && !this.selectAllIndeterminateState) {
      selected = this.props.persons.map(p => p.personId);
      selectAll = true;
    } else {
      selected = [];
      selectAll = false;
    }

    this.setState({ selectAll, selected });
  };

  /**
   * Handles the selection of a single KOL card checkbox
   */
  handleCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { selectedKols, setSelectedKols } = this.props;
    const { value } = event.target;

    if (checked) {
      setSelectedKols([...new Set([...selectedKols, value])]);
    } else {
      setSelectedKols([...new Set(selectedKols.filter(i => i !== value))]);
    }
  };



  render() {
    const { hasQuery } = this.props;

    return (
      <div>
        <ActionsWrapper>
          <div
            style={{
              marginRight: "auto",
              minWidth: 390,
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row"
            }}
          >

          </div>
        </ActionsWrapper>
        <ContentRoot>
          <FilterCard>
            <Filters />
          </FilterCard>
          {hasQuery ||
            (this.props.getTotalAppliedFiltersCount > 0 ||
              this.props.isLoadingPeople) ? (
              <div
                style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              >

                {this.props.isLoadingPeople || this.props.cardsLoading ? (
                  <LoadingState />
                ) : (
                    <StatsView />
                  )}

              </div>
            ) : (
              <NoActiveQuery />
            )}
        </ContentRoot>
      </div >
    );
  }
}

export const mapStateToProps = (state: RootState): MappedStateProps => {
  const persons = state.searchResults.results;

  return {
    user: currentUser(state)!,
    lists: getLists(state)!,
    listsAvailable: getAvailableLists(state)!,
    projectId: getSelectedProject(state)!,
    persons,
    cardsLoading: false,
    selectedListId: getSelectedListId(state),
    isLoadingPeople: isLoadingPeople(state),
    searchBarState: getSearchBarState(state),
    getTotalAppliedFiltersCount: getTotalAppliedFiltersCount(state),
    hasQuery: getHasQuery(state),
    getSortBy: getSortBy(state),
    totalHits: getTotalHits(state),
    searchView: getSearchView(state),
    checkboxDisabled: getIsSearchLoading(state) || persons.length === 0,
    pageNum: state.searchResults.pageNum,
    pageSize: state.searchResults.pageSize,
    selectedKols: state.dataExports.kols,
    allPeopleIds: state.searchResults.results.map(i => i.personId),
    bulkIdsLoading: state.dataExports.bulkActionsIdsLoading
  };
};

export const mapDispatchToProps: DispatchProps = {
  getList: getListForUser.request,
  getListOption: getListOptions.request,
  setSortBy,
  setSearchView: userSetSearchView,
  setSelectedKols: setKOLExportIds
};

export const Stats = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResultCardsClass));
