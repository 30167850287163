import * as React from "react";
import { connect } from "react-redux";
import { CardWrapper } from "../../common/Card";
import { NullState } from "../NullState";
import { RootState } from "../../../../store/reducers";
import {
  getSavedListsKolCards,
  getCardsLoadingStatus,
  getPersonIdsInListByListId
} from "../../../../store/selectors";
import { setKOLExportIds } from "../../../../store/actions/dataExports";
import { ResultCardData } from "@h1eng/interfaces";
import { LoadingState } from "../../KOLResult/Loading";
import { SavedListCard } from "./SavedListCard";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BulkActions } from "./BulkActions";
import { styled } from "@h1eng/ui-components";
import { Pagination } from "./Pagination";
import { SelectAllSavedListsCheckbox } from "./SelectAllSavedListsCheckbox";

interface MappedStateProps {
  cards: ResultCardData[];
  loading: boolean;
  pageNum: number;
  pageSize: number;
  selectedListId: string | null;
  ids: string[];
  selectedKols: string[];
}

interface DispatchProps {
  setSelectedKols: (kols: string[]) => void;
}

type SavedListCardsProps = MappedStateProps &
  DispatchProps &
  RouteComponentProps;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const SavedListCardsComponent: React.FunctionComponent<SavedListCardsProps> = ({
  cards,
  loading,
  history,
  pageNum,
  pageSize,
  selectedListId,
  ids,
  selectedKols,
  setSelectedKols
}) => {
  function handleCardSelect(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const { value } = event.target;

    if (checked) {
      setSelectedKols([...new Set([...selectedKols, value])]);
    } else {
      setSelectedKols([...new Set(selectedKols.filter(i => i !== value))]);
    }
  }

  // console.log({ selectedListId });
  return (
    <>
      <ActionsWrapper>
        <SelectAllSavedListsCheckbox
          pageNum={pageNum}
          pageSize={pageSize}
          ids={ids}
        />
        <BulkActions selectedIds={selectedKols} style={{ height: 28 }} />
      </ActionsWrapper>
      <CardWrapper>
        {cards.length === 0 || loading
          ? getNullOrLoadingView(loading)
          : cards.map((person, i) => (
              <SavedListCard
                person={person}
                key={`card-${person.firstName}-${person.lastName}-${i}`}
                selected={selectedKols.indexOf(person.personId) !== -1}
                onSelect={handleCardSelect}
              />
            ))}
      </CardWrapper>
      {cards.length > 0 && <Pagination />}
    </>
  );
};

function getNullOrLoadingView(loading: boolean) {
  if (loading) return <LoadingState />;

  return <NullState />;
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  cards: getSavedListsKolCards(state),
  loading: getCardsLoadingStatus(state)!,
  pageNum: state.lists.savedListPageNum,
  pageSize: state.lists.savedListPageSize,
  selectedListId: state.lists.selectedId,
  ids: getPersonIdsInListByListId(state),
  selectedKols: state.dataExports.kols
});

const mapDispatchToProps: DispatchProps = {
  setSelectedKols: setKOLExportIds
};

export const SavedListCards = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SavedListCardsComponent));
