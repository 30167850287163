import { createAsyncAction, createStandardAction } from "typesafe-actions";
import { UserProjectsPayload } from "@h1eng/interfaces";

export const getProjectsForUser = createAsyncAction(
  "projects/fetch.request",
  "projects/fetch.success",
  "projects/fetch.failure"
)<never, UserProjectsPayload, Error>();

export const setProject = createAsyncAction(
  "projects.setProject.request",
  "projects.setProject.success",
  "projects.setProject.failure"
)<string, UserProjectsPayload, Error>();

export const setProjectLoading = createStandardAction(
  "projects.setProject.setLoading"
)<boolean>();
