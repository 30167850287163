import {
  searchElastic,
  searchSubDocumentsElastic,
  setDocumentSearchBarQuery,
  setPageNum,
  setPageNumWithoutSideEffects,
  searchFromStoreState,
  setPaginationFrom,
  createGrowler,
  setSearchLoading,
  displayLoadingMessage,
  clearLoadingMessage
  // requestCurrentTags
} from "../actions";
import { getAnalyticsPayload } from "../selectors";
import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  filter,
  switchMap,
  mergeMap,
  map,
  catchError,
  takeUntil,
  // withLatestFrom,
  delay
} from "rxjs/operators";
import { from, of, pipe, concat } from "rxjs";
import { searchPeople, searchDocuments } from "../../apis/search";
import { RootState } from "../reducers";
import { GrowlerTypes } from "@h1eng/interfaces";

export const setPageNumberFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([setPageNum])),
    mergeMap(({ payload }) =>
      concat(
        of(setPaginationFrom(payload * state$.value.searchResults.pageSize))
      )
    )
  );

// export const fetchTagsFromSearchResultsFlow = (
//   action$: ActionsObservable<any>,
//   state$: StateObservable<RootState>
// ) =>
//   action$.pipe(
//     filter(isActionOf(searchElastic.success)),
//     withLatestFrom(state$),
//     switchMap(([{ payload }, state]) =>
//       of(
//         requestCurrentTags.request(
//           state.searchResults.results.map(i => i.personId)
//         )
//       )
//     )
//   );

export const handlePaginationFromChangeFlow = (
  action$: ActionsObservable<any>
) =>
  action$.pipe(
    filter(isActionOf(setPaginationFrom)),
    switchMap(() => of(searchFromStoreState()))
  );

export const clearLoadingMessageEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(
    filter(isActionOf([searchElastic.failure, searchElastic.success])),
    switchMap(() => of(clearLoadingMessage()))
  );

export const searchFailureAlertFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(searchElastic.failure)),
    mergeMap(() =>
      concat(
        of(setSearchLoading(false)),
        of(
          createGrowler({
            title: "Error",
            description: "There was an error performing your search",
            titleIcon: "warning",
            growler: GrowlerTypes.fail
          })
        )
      )
    )
  );

export const fetchSearchFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return action$.pipe(
    filter(isActionOf(searchElastic.request)),
    switchMap(({ payload }) =>
      concat(
        of(setPageNumWithoutSideEffects(0)),
        from(searchPeople(payload)).pipe(
          map(searchElastic.success),
          catchError(
            pipe(
              searchElastic.failure,
              of
            )
          )
        )
      )
    )
  );
};

export const fetchSearchDocumentsFlow: Epic<any, any, any> = action$ => {
  return action$.pipe(
    filter(isActionOf(searchSubDocumentsElastic.request)),
    switchMap(({ payload }) =>
      from(searchDocuments(payload)).pipe(
        map(searchSubDocumentsElastic.success),
        catchError(
          pipe(
            searchSubDocumentsElastic.failure,
            of
          )
        )
      )
    )
  );
};

export const refetchDocuments: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([setDocumentSearchBarQuery])),
    mergeMap(({ payload: { personId, query } }) =>
      concat(
        of(
          searchSubDocumentsElastic.request({
            terms: query,
            personId
          })
        )
      )
    )
  );

export const searchLoadingMessageFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(searchElastic.request),
    switchMap(() =>
      of(displayLoadingMessage()).pipe(
        delay(5000),
        takeUntil(action$.pipe(ofType(clearLoadingMessage)))
      )
    )
  );

export const handleHeapAnalyticsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(searchElastic.success),
    switchMap(() => of(sendAnalytics(state$.value)))
  );

function sendAnalytics(state: RootState) {
  const defaultResponse = { type: "", payload: "" };
  try {
    if (!window.heap) return defaultResponse;
    const payload = getAnalyticsPayload(state);

    if (payload.query !== "") {
      window.heap.track("Search", payload);
    }
  } catch (e) {
    // This is for analytics tracking; should not kill app on failure
  }

  return defaultResponse;
}
