import * as React from "react";
import { connect } from "react-redux";
import { FilterSection } from "../FilterSection";
import { RootState } from "../../../../../store/reducers";
import { getPublicationsFiltersCount } from "../../../../../store/selectors";
import { TextFilterWrapper } from "../../components/TextFilterWrapper";
import { PublicationsMinCount } from "./PublicationsMinCount";
import { PublicationsSocialMediaMinCount } from "./PublicationsSocialMediaMinCount"
// import { JournalFilters } from "./JournalFilters";
import { TypeFilters } from "./TypeFilters";

interface MappedStateProps {
  filterCount: number;
}

type Props = MappedStateProps;

function PublicationsComponent(props: Props) {
  return (
    <FilterSection
      sectionKey="pubs"
      title="Publications"
      count={props.filterCount}
      id={"Publications-DropDown"}
    >
      <TextFilterWrapper>
        <PublicationsMinCount />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <PublicationsSocialMediaMinCount />
      </TextFilterWrapper>
      {/* <TextFilterWrapper>
        <JournalFilters />
      </TextFilterWrapper> */}
      <TextFilterWrapper>
        <TypeFilters />
      </TextFilterWrapper>
    </FilterSection>
  );
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  filterCount: getPublicationsFiltersCount(state)
});

export const Publications = connect(mapStateToProps)(PublicationsComponent);
