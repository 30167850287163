/* tslint:disable:cyclomatic-complexity curly */
import * as React from "react";
import { styled, B1, B2, B4, H5 } from "@h1eng/ui-components";
import { connect } from "react-redux";
import {
  PublicationInterface,
  PublicationsInterface,
  PublicationSummaryInterface,
  GenericSearchResultInterface,
  CombinedDocumentsType,
  GenericSearchEnum
} from "@h1eng/interfaces";
import { Card } from "./ProfileElements";

import { ProfileCard } from "./ProfileShared";
import { PublicationLink } from "./DocLink";
import { formatStats } from "@h1eng/format-util";
import {
  ProfileDocumentSearch,
  SetDocumentSearchBarQueryInterface
} from "./ProfileDocumentSearch";
import {
  getDocumentSearch,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../store/selectors";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../store/actions";
import { MultiRender } from "./MultiDocRender";
import {
  SearchBarQureyHelper,
  SearchBarQureyDisplay,
  filterDocsByDate,
  filterFoundDocs
} from "./SearchHelpFunctions";
import { PublicationsVisualization } from "./DataVisualizations/Publications";
import { SummaryItem, Numerator, Denominator } from "./components/SummaryItem";
import { TextTruncate } from "../common/TextTruncate";
import { DateRangeDropdown } from "../common/DateRangeDropdown";
import { uniqBy } from "lodash";
import { Dropdown } from "../../curie/common/Dropdown";
const AuthorsWrapper = styled.div`
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const Summary = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ecf0f1;
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
`;

const Spacer = styled.div`
  margin-bottom: 5px;
`;

function formatDate(pubDate?: number) {
  if (!pubDate) return;

  const date = new Date(pubDate);

  return `${date.toLocaleString("en-us", {
    month: "short"
  })} ${date.getFullYear()}`;
}

export const PUBLICATION: React.SFC<PublicationInterface> = props => {
  const authors = (props.authors || [])
    .map(i => `${i.firstName} ${i.lastName}`.trim())
    .filter(i => !!i);

  return (
    <ProfileCard boxShadowColor={"#80d0e1"}>
      <H5 deEmphasized>PUBLICATION</H5>
      <Spacer />

      <PublicationLink personId={props.personId} docId={props.id}>
        {props.title}
      </PublicationLink>

      {authors.length > 0 && (
        <AuthorsWrapper>
          <TextTruncate lines={1}>{authors.join(", ")}</TextTruncate>
        </AuthorsWrapper>
      )}

      <Spacer />
      <Spacer />

      <Horizontal>
        <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          {[
            ["PMID", props.pmid],
            [<>Date&nbsp;Published</>, formatDate(props.datePublished)],
            ["Citations", props.citationsCount ? props.citationsCount : 0],
            [<>Social&nbsp;Media&nbsp;Mentions</>, props.socialMediaCount],
            ["Journal", props.journal]
          ]
            .filter(
              p =>
                p[1] !== undefined &&
                p[1] !== null &&
                p[1] !== 0 &&
                p[1] !== "0"
            )
            .map((p, i) => {
              let value: string | number | undefined | React.ReactElement<any> =
                p[1];

              const styles: React.CSSProperties = {
                display: "flex",
                paddingRight: "15px"
              };

              if (p[0] === "Journal") {
                value = <TextTruncate lines={1}>{value}</TextTruncate>;
                styles.width = "20%";
                styles.minWidth = 300;
                styles.flexGrow = 1;
                styles.flexShrink = 0;
              } else {
                value = (value as number).toLocaleString();
              }

              return (
                <div key={i} style={styles}>
                  <div style={{ marginRight: "5px" }}>
                    <B1 small>{p[0]}: </B1>
                  </div>
                  <B2 small style={{ display: "inline-block", width: "100%" }}>
                    {value}
                  </B2>
                </div>
              );
            })}
        </div>
      </Horizontal>
    </ProfileCard>
  );
};

const PublicationSummaryBarStats = (
  outOF: any,
  computed: any,
  publications: any[] = []
) => {
  const hindex =
    outOF.hIndex &&
      outOF.hIndex !== 0 &&
      //@ts-ignore
      outOF.hIndex !== "0" ? (
        <SummaryItem
          label="h-index"
          item={Number.parseInt(outOF.hIndex.toString(), 10)}
        />
      ) : (
        <> </>
      );
  const summary = (
    <Summary>
      <SummaryGrid>
        <Details>
          <SummaryItem
            label="Total"
            item={
              <>
                <Numerator>{outOF.publicationCount}</Numerator>
                <Denominator>/{computed.publicationCount}</Denominator>
              </>
            }
          />
          <SummaryItem
            label="Citations"
            item={
              <>
                <Numerator>{outOF.citationCount}</Numerator>
                <Denominator>/{computed.citationCount}</Denominator>
              </>
            }
          />
          {hindex}
          <SummaryItem
            label="Social Media Mentions"
            item={
              <>
                <Numerator>{outOF.socialMediaMentions}</Numerator>
                <Denominator>/{computed.socialMediaMentions}</Denominator>
              </>
            }
          />
        </Details>
        <PublicationsVisualization publications={publications} />
      </SummaryGrid>
    </Summary>
  );
  return summary;
};

const PublicationSummaryBar: React.SFC<PublicationSummaryInterface> = props => {
  const pubCount =
    props.publicationCount! > 0 ? (
      <SummaryItem label="Total" item={props.publicationCount} />
    ) : (
        <></>
      );

  const citationCount =
    props.citationCount! > 0 ? (
      <SummaryItem label="Citations" item={formatStats(props.citationCount!)} />
    ) : (
        <></>
      );
  const hIndex =
    props.hIndex &&
      props.hIndex !== 0 &&
      //@ts-ignore
      props.hIndex !== "0" ? (
        <SummaryItem
          label="h-index"
          item={Number.parseInt(props.hIndex.toString(), 10)}
        />
      ) : (
        <></>
      );
  const socialMediaMentions =
    props.socialMediaMentions! > 0 ? (
      <SummaryItem label="Social Media Mentions" item={formatStats(props.socialMediaMentions!)} />
    ) : (
        <></>
      );

  return (
    <Summary>
      <div style={{ marginBottom: "10px" }}>
        <H5 deEmphasized>SUMMARY</H5>
      </div>
      <SummaryGrid>
        <Details>
          {pubCount}
          {citationCount}
          {hIndex}
          {socialMediaMentions}
        </Details>
        <PublicationsVisualization publications={props.publications} />
      </SummaryGrid>
    </Summary>
  );
};

interface PublicationsSectionClassDispatcher {
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
  setDocumentSearchBarFilterDate: (t: {
    filterDate: number;
    displayFilterDateString: string;
  }) => void;
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[] };
  filterDate: number;
  filterDateText: string;
}

type Props = PublicationsInterface & PublicationsSectionClassDispatcher;
export class PublicationSectionClass extends React.Component<
  Props & { personId: string },
  any
  > {
  constructor(props: Props & { personId: string }) {
    super(props);

    this.state = {
      publicationFilter: { text: "All types", value: "All types" }  
    };
  }
  generatePublication = (pubs: PublicationInterface[]) => {
    return {
      publicationCount: pubs.length,
      citationCount: pubs.reduce((pv, cv) => {
        const count = Number.parseInt(cv.citationsCount!.toString(), 10);
        if (
          count &&
          !Number.isNaN(count) &&
          Number.isFinite(count) &&
          Number.isSafeInteger(count)
        ) {
          pv += count;
        }

        return pv;
      }, 0),
      hIndex: this.props.summary!.hIndex,
      socialMediaMentions: pubs.reduce((pv, cv) => {
        const count = Number.parseInt(cv.socialMediaCount!.toString(), 10);
        if (
          count &&
          !Number.isNaN(count) &&
          Number.isFinite(count) &&
          Number.isSafeInteger(count)
        ) {
          pv += count;
        }

        return pv;
      }, 0),
    } as PublicationSummaryInterface;
  };

  filterPublicationTypes() {
    let pubs = this.props.publications;;
    if (this.state.publicationFilter.value !== "All types" && this.state.publicationFilter.value !== "Has Social Media Mentions") {
      pubs = this.props.publications!.filter(p => {
        const values = p.publicationTypes || [];
        return values.includes(this.state.publicationFilter.value);
      });
    }

    if (this.state.publicationFilter.value === "Has Social Media Mentions" && pubs) {
      pubs = pubs.filter((p) => p.socialMediaCount && p.socialMediaCount > 0);
    }
    if (!pubs) {
      return [];
    }
    return pubs;
  }

  render() {
    let hasSocialMediaMentions = false;
    let publicationTypeOptions = uniqBy(
      this.props.publications!.reduce(
        (pv, cv) => {
          const values = cv.publicationTypes || [];
          if (values.length > 0) {
            values.forEach(e => {
              pv.push({ text: e, value: e });
            });
          }
          if (cv.socialMediaCount > 0) {
            hasSocialMediaMentions = true;
          }

          return pv;
        },
        [{ text: "All types", value: "All types" }]
      ),
      "value"
    );

    const insert = (arr: any, index: any, newItem: any) => [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index)
    ]
    if (hasSocialMediaMentions) {
      publicationTypeOptions = insert(publicationTypeOptions, 1, { text: "Has Social Media Mentions", value: "Has Social Media Mentions" });

    }
    const publicationsFilterdByDate = filterDocsByDate(
      this.props.filterDate,
      this.props.filterDateText,
      this.filterPublicationTypes() || []
    );

    const filterFoundPubs = filterFoundDocs(
      this.props.documentSearch,
      publicationsFilterdByDate
    );

    const summary = this.props.searchBarState.query.length ? (
      PublicationSummaryBarStats(
        this.generatePublication(filterFoundPubs),
        this.generatePublication(publicationsFilterdByDate),
        this.filterPublicationTypes()
      )
    ) : (
        <PublicationSummaryBar
          {...this.generatePublication(publicationsFilterdByDate)}
          publications={this.filterPublicationTypes() || []}
        />
      );

    return (
      <Card>
        <div style={{ margin: "15px" }}>
          <ProfileDocumentSearch
            customWidth={"100%"}
            personId={this.props.personId}
          />
        </div>
        <div
          style={{
            paddingLeft: 20,
            display: "grid",
            flexGrow: 1,
            flexShrink: 0,
            flexBasis: "auto",
            gridTemplateColumns: "275px 270px",
            alignItems: "center",
            maxWidth: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: "auto"
            }}
          >
            <Dropdown
              label={"Publication Type"}
              options={publicationTypeOptions}
              selected={this.state.publicationFilter.value}
              onSelect={(v, t) => {
                this.setState({ publicationFilter: { text: t, value: t } });
              }}
            />
          </div>
          {/* </div> */}

          {/* <div style={{ display: "flex", flexGrow: 0, flexShrink: 0, flexBasis: "auto" }}> */}
          <div
            style={{
              display: "flex",
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: "auto",
              marginLeft: -25
            }}
          >
            <DateRangeDropdown />
          </div>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <SearchBarQureyHelper />
        </div>
        <SearchBarQureyDisplay />
        {summary}

        <MultiRender
          docs={this.filterPublicationTypes()!.map(
            e => e as CombinedDocumentsType
          )}
          dateFilter={this.props.filterDate}
          types={[GenericSearchEnum.PUBLICATION]}
          limitNoSearch={false}
          personId={this.props.personId}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
};

export const PublicationSection = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(PublicationSectionClass as any);
