import { ListMeta } from "../components/curie/ListManagement/ListManagementInterfaces";
import { ResultCardData } from "@h1eng/interfaces";
import { ENDPOINTS } from "./api-endpoints";
import { ListVO } from "../models/ListVO";

export async function fetchListsForPersons(
  personIds: string[],
  projectId: string,
  userId: string
) {
  return fetch(`${ENDPOINTS.listsForPerson}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ personIds, projectId, userId })
  }).then(res => res.json());
}

export async function fetchListsForUser(
  personIds: string[],
  projectId: string,
  userId: string
) {
  // promise all so we don't end up with wasteful re-renders.
  return Promise.all(
    personIds.map(personId => {
      return fetch(
        `${
          ENDPOINTS.listsForUser
        }?personId=${personId}&projectId=${projectId}&userId=${userId}`,
        {
          mode: "cors",
          credentials: "include"
        }
      )
        .then(res => res.json())
        .then((lists: ListMeta[]) => {
          return { personId, lists };
        });
    })
  );
}
export async function fetchListsForPerson(
  userId: string,
  personId: string,
  projectId: string
) {
  return fetch(
    `${
      ENDPOINTS.listsForPerson
    }?personId=${personId}&projectId=${projectId}&userId=${userId}`,
    {
      mode: "cors",
      credentials: "include"
    }
  )
    .then(res => res.json())
    .then((lists: ListMeta[]) => {
      return { personId, lists };
    });
}

export const fetchAvailableLists = async (
  userId: string,
  projectId: string
) => {
  return await fetch(
    `${ENDPOINTS.listsForUser}?projectId=${projectId}&userId=${userId}`,
    {
      mode: "cors",
      credentials: "include"
    }
  ).then(response => response.json());
};

export const addToList = async (personIds: string[], listId: string) => {
  await fetch(ENDPOINTS.addToList, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      personIds,
      listId
    })
  });
};

export const createNewList = ({
  personIds,
  listName,
  projectId,
  userId
}: ListVO) => {
  return fetch(ENDPOINTS.createList, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      personIds,
      listName,
      projectId,
      userId
    })
  })
    .then(res => res.json())
    .then(ans => {
      const as: ListVO = {
        id: ans.list.id,
        personIds,
        listName,
        projectId,
        userId
      };
      return as;
    });
};

// Axios.post(
//   ENDPOINTS.createList,
//   {
//     personIds,
//     listName,
//     projectId,
//     userId
//   },
//   { withCredentials: true }
// ).then(
//   ({
//     data: {
//       list: { id }
//     }
//   }): ListVO => ({
//     id,
//     personIds,
//     listName,
//     projectId,
//     userId
//   })

export const fetchPersonsForList = async (
  listId: string
): Promise<ResultCardData[]> => {
  const personIds: string[] = await fetch(
    `${ENDPOINTS.personsInList}?listId=${listId}`
  ).then(res => res.json());

  return await fetch(ENDPOINTS.kolCards, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ personIds })
  }).then(res => res.json());
};

export const removeFromList = async (personIds: string[], listId: string) => {
  await fetch(ENDPOINTS.removeFromList, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      personIds,
      listId
    })
  });
};

export const deleteListApi = async (listId: string) => {
  return await fetch(ENDPOINTS.deleteList, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      listId
    })
  });
};
