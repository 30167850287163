import {
  createAsyncAction,
  ActionType,
  createStandardAction
} from "typesafe-actions";
import {
  FilterSearchInterface,
  GenericSearchInterface,
  GenericSearchResultInterface,
  PersonSearchResponse,
  SearchView,
  PersonCard
} from "@h1eng/interfaces";

export const setPaginationFrom = createStandardAction(
  "search/pagination/setFrom"
)<number>();

export const searchElastic = createAsyncAction(
  "search/request",
  "search/success",
  "search/failure"
)<FilterSearchInterface, PersonSearchResponse, Error>();

export const updateSearchResults = createStandardAction(
  "search/results/update"
)<PersonCard[]>();

export const searchSubDocumentsElastic = createAsyncAction(
  "searchDocuments/request",
  "searchDocuments/success",
  "searchDocuments/failure"
)<GenericSearchInterface, GenericSearchResultInterface[], Error>();

export const setPageNum = createStandardAction("search/setPageNum")<number>();

export const setPageNumWithoutSideEffects = createStandardAction(
  "search/setPageNum/noSideEffects"
)<number>();

export const setSearchLoading = createStandardAction("search/setLoading")<
  boolean
>();
export const setSearchView = createStandardAction("search/setSearchView")<
  SearchView
>();

export const displayLoadingMessage = createStandardAction(
  "search/loadingMessage.display"
)<never>();

export const clearLoadingMessage = createStandardAction(
  "search/loadingMessage.clear"
)<never>();

export const fetchBulkActionIdsForSearch = createAsyncAction(
  "search/bulk/peopleIds.request",
  "search/bulk/peopleIds.success",
  "search/bulk/peopleIds.failure",
  "search/bulk/peopleIds.cancel"
)<never, string[], Error, never>();

export type SearchAction =
  | ActionType<typeof searchElastic>
  | ActionType<typeof setSearchView>
  | ActionType<typeof searchSubDocumentsElastic>
  | ActionType<typeof setPageNum>
  | ActionType<typeof setPageNumWithoutSideEffects>
  | ActionType<typeof setSearchLoading>
  | ActionType<typeof displayLoadingMessage>
  | ActionType<typeof clearLoadingMessage>
  | ActionType<typeof setPaginationFrom>
  | ActionType<typeof fetchBulkActionIdsForSearch>
  | ActionType<typeof updateSearchResults>;
