/* tslint:disable:cyclomatic-complexity */
import { getType } from "typesafe-actions";
import {
  searchElastic,
  searchSubDocumentsElastic,
  // setPageNum,
  // setPageNumWithoutSideEffects,
  setPaginationFrom,
  setSearchLoading,
  displayLoadingMessage,
  clearLoadingMessage,
  SearchAction,
  setSearchView,
  updateSearchResults
} from "../actions";
import {
  GenericSearchResultInterface,
  SearchView,
  PersonSearchResponse
} from "@h1eng/interfaces";

export interface SearchState extends PersonSearchResponse {
  documentSearchResults: GenericSearchResultInterface[];
  loading: boolean;
  from: number;
  pageNum: number;
  pageSize: number;
  documentSearchLoading: boolean;
  displayLoadingMessage: boolean;
  searchView: SearchView;
}

export const searchInitialState: SearchState = {
  documentSearchResults: [],
  loading: false,
  total: 0,
  pageNum: 0,
  pageSize: 15,
  from: 0,
  results: [],
  searchView: SearchView.SearchedWork,
  documentSearchLoading: false,
  displayLoadingMessage: false,
  normalizedRange: { min: 0, max: 0 },
  ranges: {
    citationCount: { min: 0, max: 0 },
    trialCount: { min: 0, max: 0 },
    congressCount: { min: 0, max: 0 },
    grantCount: { min: 0, max: 0 },
    grantSum: { min: 0, max: 0 },
    paymentCount: { min: 0, max: 0 },
    paymentSum: { min: 0, max: 0 },
    publicationCount: { min: 0, max: 0 },
    totalCollaborators: { min: 0, max: 0 }
  }
};

export const initialState = searchInitialState;

export const searchReducer = (
  state: SearchState = searchInitialState,
  action: SearchAction
): SearchState => {
  switch (action.type) {
    case getType(setSearchView): {
      return {
        ...state,
        searchView: action.payload
      };
    }
    case getType(setPaginationFrom): {
      return {
        ...state,
        from: action.payload
      };
    }
    // case getType(setPageNumWithoutSideEffects):
    // case getType(setPageNum): {
    //   return {
    //     ...state,
    //     pageNum: action.payload
    //   };
    // }

    case getType(searchElastic.request): {
      return { ...state, loading: true, total: 0 };
    }

    case getType(searchElastic.success): {
      return {
        ...state,
        total: action.payload.total,
        loading: false,
        results: action.payload.results || []
      };
    }

    case getType(searchSubDocumentsElastic.request): {
      return {
        ...state,
        documentSearchLoading: true
      };
    }

    case getType(searchSubDocumentsElastic.success): {
      return {
        ...state,
        documentSearchLoading: false,
        documentSearchResults:
          action.payload && action.payload.length ? action.payload : []
      };
    }

    case getType(setSearchLoading): {
      return {
        ...state,
        loading: action.payload
      };
    }

    case getType(displayLoadingMessage): {
      return {
        ...state,
        displayLoadingMessage: true
      };
    }

    case getType(clearLoadingMessage): {
      return {
        ...state,
        displayLoadingMessage: false
      };
    }

    case getType(updateSearchResults): {
      return {
        ...state,
        results: action.payload
      };
    }
  }

  return state;
};
