
import * as React from "react";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(24)
    }
  });

interface TextMetric {
  title: string;
  stats: { count: number; value: string }[];
}

interface ComponentProps {
  metrics: TextMetric[];
}

type Props = ComponentProps & WithStyles<typeof styles>;

const TextMetricsCard = ({ metrics, classes }: Props) => {
  return (
    <div className={classes.root}>
      {metrics.map(metric => (
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {`Top ${metric.title}`}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>{metric.stats.map(i => i.value).join(", ")}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  );
};

export default withStyles(styles)(TextMetricsCard);
