import { ENDPOINTS } from "./api-endpoints";

export const getPersons = (
  projectId: string,
  userId: string,
  personIds: string[]
) => {
  if (!projectId || !userId || !personIds || !personIds.length) {
    return [];
  }

  return fetch(`${ENDPOINTS.kolCards}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      personIds
    })
  }).then(res => res.json());
};
