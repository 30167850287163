/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import classNames from "classnames";
// @ts-ignore
import numeral from "numeral";
import NavigationButtons from "./NavigationButtons";
import {
  pageCount as getPageCount,
  getItemRangeInPage
} from "@h1eng/pagination";
import { GoToPageInput } from "./GoToPageInput";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    activeButton: {
      backgroundColor: theme.palette.action.hover,
      fontWeight: "bold"
    },
    button: {
      padding: 6,
      minWidth: 36,
      "&:not(:first-of-type)": {
        marginLeft: 2
      },
      "&:not(:last-of-type)": {
        marginRight: 2
      }
    },
    breakView: {
      fontFamily: theme.typography.fontFamily,
      cursor: "default",
      paddingLeft: 6,
      paddingRight: 6
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: "auto"
    },
    navItems: {},
    inputWrapper: {
      maxWidth: 135,
      width: "100%"
    },
    countLabel: {
      fontFamily: theme.typography.fontFamily,
      cursor: "default",
      color: "#333",
      fontSize: 14
    }
  });

export interface PaginationProps {
  pageNum: number;
  pageSize: number;
  total: number;
  loadPage: (pageNum: number) => void;
  scrollOnPageChange?: boolean;
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
}

type Props = PaginationProps & WithStyles<typeof styles>;

const PaginationLink: React.FunctionComponent<ButtonProps> = props => (
  <Button {...props} />
);

const PaginationComponent: React.FunctionComponent<Props> = ({
  pageNum,
  pageSize,
  total,
  loadPage,
  scrollOnPageChange,
  classes,
  pageRangeDisplayed = 2,
  marginPagesDisplayed = 3
}) => {
  const pageCount = getPageCount({ count: total, pageSize });

  if (pageCount <= 1) {
    return null;
  }

  const handleGoToPage = (num: number) => {
    if (isNaN(num) || num < 0 || num >= pageCount || num === pageNum) {
      return;
    }
    if (scrollOnPageChange) window.scrollTo(0, 0);
    loadPage(num);
  };

  const goToPage = (num: number) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    handleGoToPage(num);
  };

  const pagination = () => {
    const res = [];

    const getPaginationLink = (index: number) => (
      <PaginationLink
        key={`pagination-link-${index}`}
        onClick={goToPage(index)}
        className={classNames(classes.button, {
          [classes.activeButton]: index === pageNum
        })}
      >
        {index + 1}
      </PaginationLink>
    );

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        res.push(getPaginationLink(index));
      }
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;

      // If the pageNum page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the pageNum page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (pageNum > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - pageNum;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (pageNum < pageRangeDisplayed / 2) {
        leftSide = pageNum;
        rightSide = pageRangeDisplayed - leftSide;
      }

      let index;
      let page;
      let breakView;

      for (index = 0; index < pageCount; index++) {
        page = index + 1;

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= marginPagesDisplayed) {
          res.push(getPaginationLink(index));
          continue;
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - marginPagesDisplayed) {
          res.push(getPaginationLink(index));
          continue;
        }

        // If the page index is near the pageNum page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= pageNum - leftSide && index <= pageNum + rightSide) {
          res.push(getPaginationLink(index));
          continue;
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (res[res.length - 1] !== breakView) {
          breakView = (
            <span className={classes.breakView} key={`break-view-${index}`}>
              ...
            </span>
          );
          res.push(breakView);
        }
      }
    }

    return res;
  };

  const items = pagination();
  const [start, end] = getItemRangeInPage({
    pageNum,
    pageSize,
    count: total
  });
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className={classes.navItems}
        >
          {items}
        </nav>
        <span className={classes.countLabel}>
          {numeral(start).format("0,0")} - {numeral(end).format("0,0")} of{" "}
          {numeral(total).format("0,0")}
        </span>
        <NavigationButtons
          pageNum={pageNum}
          lastPage={pageCount - 1}
          handlePageChange={goToPage}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.inputWrapper}>
          <GoToPageInput goToPage={handleGoToPage} pageCount={pageCount} />
        </div>
      </div>
    </div>
  );
};

export const Pagination = withStyles(styles)(PaginationComponent);
