import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import { RootState } from "../../reducers";
import { initialState as SearchInitialState } from "../../reducers/search";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  withLatestFrom,
  filter
} from "rxjs/operators";
import { of, concat, empty } from "rxjs";
import {
  searchElastic,
  setSortBy,
  setQuery,
  setNameQuery,
  setKeywordQuery,
  setSpecialtyValues,
  setCountryValues,
  setStateValues,
  setInstitutionValues,
  setPublicationMinCount,
  setPublicationSocialMinCount,
  setPublicationJournalValues,
  setTagNameValues,
  setPublicationTypeValues,
  setTrialMinCount,
  setTrialStatusValues,
  setTrialPhaseValues,
  setTrialStudyTypeValues,
  setTrialFunderTypeValues,
  setTrialSponsorValues,
  setCongressMinCount,
  setCongressNameValues,
  setCongressTypeValues,
  setCongressOrganizerNameValues,
  setCongressSessionTypeValues,
  setGrantMinAmount,
  setGrantFunderValues,
  setPaymentMinAmount,
  setPaymentCompanyValues,
  setPaymentDrugOrDeviceValues,
  setPaymentFundingTypeValues,
  searchFromStoreState,
  setSearchType,
  clearSearchFilters,
  setSearchView,
  setPageNumWithoutSideEffects,
  setDateRangeFilterMin,
  setDateRangeFilterMax,
  setInstitutionTypeValues,
  getInitialFilterOptions,
  clearKOLExportIds,
  updateSearchResults,
  SearchFilterAction
} from "../../actions";
import {
  FilterSearchInterface,
  SearchTypes,
  SearchView
} from "@h1eng/interfaces";
import {
  getQuery,
  getTotalAppliedFiltersCount,
  getActiveFilterBreakDown,
  getAppliedFiltersCount,
  isLoadingPeople
} from "../../selectors";
import { DEBOUNCE_TIME } from ".";
import { isActionOf } from "typesafe-actions";
import { setSearchLoading } from "../../actions/search";

const searchOnUpdatedFiltersFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(
      setSortBy,
      setNameQuery,
      setKeywordQuery,
      setSpecialtyValues,
      setCountryValues,
      setStateValues,
      setInstitutionValues,
      setInstitutionTypeValues,
      setPublicationJournalValues,
      setTagNameValues,
      setPublicationTypeValues,
      setTrialStatusValues,
      setTrialPhaseValues,
      setTrialStudyTypeValues,
      setTrialFunderTypeValues,
      setTrialSponsorValues,
      setCongressNameValues,
      setCongressTypeValues,
      setGrantFunderValues,
      setPaymentCompanyValues,
      setPaymentDrugOrDeviceValues,
      setPaymentFundingTypeValues,
      setDateRangeFilterMin,
      setDateRangeFilterMax,
      setCongressOrganizerNameValues,
      setCongressSessionTypeValues
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      getQuery(state).length > 0 || getTotalAppliedFiltersCount(state) > 0
        ? concat(
            of(setPageNumWithoutSideEffects(0)),
            of(
              searchElastic.request(
                buildSearchElasticArgs({
                  ...state,
                  searchResults: {
                    ...state.searchResults,
                    from: 0
                  }
                })
              )
            )
          )
        : concat(
            of(setPageNumWithoutSideEffects(0)),
            of(updateSearchResults([]))
          )
    )
  );

const handleLoadingMinCountDebounceIssueEpic = (
  action$: ActionsObservable<SearchFilterAction>
) =>
  action$.pipe(
    filter(
      isActionOf([
        setPublicationMinCount,
        setPublicationSocialMinCount,
        setTrialMinCount,
        setCongressMinCount
      ])
    ),
    switchMap(({ payload }) =>
      payload === null ? empty() : of(setSearchLoading(true))
    )
  );

const searchWithStoreStateValuesFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(searchFromStoreState),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      of(searchElastic.request(buildSearchElasticArgs(state)))
    )
  );

const debounceNumberValuesAndSearchFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(
      setPublicationMinCount,
      setPublicationSocialMinCount,
      setTrialMinCount,
      setCongressMinCount,
      setGrantMinAmount,
      setPaymentMinAmount
    ),
    debounceTime(DEBOUNCE_TIME),
    switchMap(() =>
      getQuery(state$.value).length > 0 ||
      getTotalAppliedFiltersCount(state$.value) > 0
        ? of(searchElastic.request(buildSearchElasticArgs(state$.value))).pipe(
            takeUntil(action$.pipe(skip(1)))
          )
        : of(searchElastic.success(getElasticSuccessInitialState()))
    )
  );

const handleClearSearchFiltersFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(clearSearchFilters),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      getQuery(state) !== ""
        ? of(searchFromStoreState())
        : of(getInitialFilterOptions.request())
    )
  );

const handleSetSearchTypeFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(setSearchType),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      let searchView: SearchView;
      if (payload === SearchTypes.NAME) {
        searchView = SearchView.TotalWork;
      } else {
        searchView = SearchView.SearchedWork;
      }

      if (Boolean(getQuery(state)) || getAppliedFiltersCount(state) > 0) {
        return concat(
          of(clearKOLExportIds()),
          of(setSearchView(searchView)),
          of(setPageNumWithoutSideEffects(0)),
          of(searchElastic.request(buildSearchElasticArgs(state)))
        );
      }

      return concat(
        of(clearKOLExportIds()),
        of(setSearchView(searchView)),
        of(setPageNumWithoutSideEffects(0))
      );
    })
  );

const handleSetQueryFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(setQuery),
    switchMap(({ payload }) =>
      state$.value.searchFilters.searchType === SearchTypes.KEYWORD
        ? of(setKeywordQuery(payload))
        : of(setNameQuery(payload))
    )
  );

function getElasticSuccessInitialState() {
  return {
    total: SearchInitialState.total,
    pageNum: SearchInitialState.pageNum,
    pageSize: SearchInitialState.pageSize,
    results: [],
    from: 0,
    ranges: SearchInitialState.ranges,
    normalizedRange: SearchInitialState.normalizedRange
  };
}

export function buildSearchElasticArgs(
  state: RootState
): FilterSearchInterface {
  return {
    userId: state.user!.user!.id,
    query: getQuery(state)
      .split(",")
      .filter((i: string) => i !== ""),
    projectId: state.projects.projectId as string,
    filters: state.searchFilters,
    sortBy: state.searchFilters.sortBy,
    searchType: state.searchFilters.searchType,
    activeFilters: getActiveFilterBreakDown(state),
    from: state.searchResults.from,
    pageSize: state.searchResults.pageSize
  };
}

export default [
  handleSetSearchTypeFlow,
  searchOnUpdatedFiltersFlow,
  debounceNumberValuesAndSearchFlow,
  searchWithStoreStateValuesFlow,
  handleClearSearchFiltersFlow,
  handleSetQueryFlow,
  handleLoadingMinCountDebounceIssueEpic
];
