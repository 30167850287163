/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
// @ts-ignore
import Heap from "react-heap";
import { currentUser } from "../store/selectors";
import { RootState } from "../store/reducers";

const appId =
  process.env.NODE_ENV === "development"
    ? // The configured "Local Dev" project in Heap
      process.env.HEAP_ID || "3976433945"
    : process.env.HEAP_ID;

declare global {
  interface Window {
    heap: any;
  }
}

interface HeapProps {
  appId: string;
  userId: string;
  userData:
    | {
        firstName?: string;
        lastName?: string;
        email?: string;
      }
    | any;
}

interface MappedStateProps {
  heapProps: HeapProps | null;
}

export function hasCompanyEmail(email: unknown) {
  if (email && typeof email === "string") {
    return Boolean(
      email
        .toLowerCase()
        .trim()
        .match(/h1insights.com|shoregrp.com/)
    );
  }

  return false;
}

const HeapComponent: React.FunctionComponent<MappedStateProps> = ({
  heapProps
}) => {
  if (!heapProps) return null;

  if (
    heapProps &&
    heapProps.userData &&
    heapProps.userData.email &&
    hasCompanyEmail(heapProps.userData.email)
  ) {
    return null;
  }

  // @ts-ignore
  return <Heap {...heapProps} />;
};

const mapStateToProps = (state: RootState): MappedStateProps => {
  let heapProps: HeapProps | null = null;

  if (!!appId) {
    const user = currentUser(state);

    heapProps = {
      appId,
      userId: user ? user.id : "guest",
      userData: user
        ? {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        : {}
    };
  }

  return { heapProps };
};

export default connect(mapStateToProps)(HeapComponent);
