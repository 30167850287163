/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { PublicationInterface } from "@h1eng/interfaces";
import { DocumentCard, Text, CardSection, ExternalLink } from "../DocumentCard";
import { getLinkUrl } from "../../Profile/AssetLink";
import { TextTruncate } from "../../common/TextTruncate";

interface PublicationProps {
  publication: PublicationInterface;
}

export class Publication extends React.Component<PublicationProps> {
  render() {
    const { publication } = this.props;
    const linkUrl = getLinkUrl(publication);

    return (
      <DocumentCard
        badgeColor="#80D0E1"
        badgeUrl="https://d10ba5vkyum61s.cloudfront.net/img/publications-badge.svg"
      >
        <CardSection>
          <Text fullWidth bold fontSize={14}>
            {publication.name || publication.title}
          </Text>
          <Text fullWidth bold style={{ color: "rgb(115, 115, 115)" }}>
            {(publication.authors || [])
              .map(i => `${i.firstName} ${i.lastName}`.trim())
              .filter(i => !!i)
              .join(", ")}
          </Text>
          <div style={{ marginTop: 20 }}>
            {!!publication.pmid && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  PMID:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {publication.pmid}
                </Text>
              </div>
            )}
            {!!publication.journal && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  Journal:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {publication.journal}
                </Text>
              </div>
            )}
            {!!publication.citationsCount && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  Citation count:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {publication.citationsCount}
                </Text>
              </div>
            )}
            {!!publication.socialMediaCount && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  Social Media Mentions:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {publication.socialMediaCount}
                </Text>
              </div>
            )}
            {!!linkUrl && (
              <ExternalLink
                style={{ width: "100%", display: "block", marginTop: 4 }}
                href={linkUrl}
              >
                Publication Source
              </ExternalLink>
            )}
          </div>
        </CardSection>
        {!!publication.abstract && (
          <CardSection title="Abstract">
            <Text fontSize={14}>
              <TextTruncate lines={3}>{publication.abstract}</TextTruncate>
            </Text>
          </CardSection>
        )}

        {publication.substances.length > 0 && (
          <CardSection title="Substances">
            <Text fontSize={14}>{publication.substances.join(", ")}</Text>
          </CardSection>
        )}

        {
          // @TODO: uncomment when data is fixed
          // publication.keywords.length > 0 && (
          //   <CardSection title="Keywords">
          //     <Text fontSize={14}>{publication.keywords.join(", ")}</Text>
          //   </CardSection>
          // )
        }
      </DocumentCard>
    );
  }
}
