/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Checkbox from "@material-ui/core/Checkbox";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { SEARCH_RESULT_LIMIT } from "@h1eng/interfaces";
import { RootState } from "../../../../store/reducers";
import {
  fetchBulkActionIdsForSearch,
  clearKOLExportIds,
  setKOLExportIds
} from "../../../../store/actions/dataExports";

const MaxRequestSize = 500;

const styles = (theme: Theme) =>
  createStyles({
    snackbarContentRoot: {
      backgroundColor: theme.palette.primary.main,
      flexWrap: "inherit"
    },
    snackbarContentMessage: {
      color: theme.palette.primary.contrastText,
      fontWeight: 500
    },
    selectButton: {
      backgroundColor: "#7061FF",
      color: "inherit"
    },
    clearButton: {
      color: "inherit",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  });

type Props = { disabled?: boolean } & WithStyles;

const SelectAllKolsCheckbox: React.FC<Props> = ({
  classes,
  disabled = false
}) => {
  const { currentIds, resultCount, selectedIds, from, loading } = useSelector(
    (state: RootState) => {
      return {
        currentIds: state.searchResults.results.map(i => i.personId),
        resultCount: state.searchResults.total,
        selectedIds: state.dataExports.kols,
        from: state.searchResults.from,
        loading: state.dataExports.bulkActionsIdsLoading
      };
    }
  );

  const allCurrentSelected =
    selectedIds.length > 0 &&
    currentIds.length > 0 &&
    currentIds.every(i => selectedIds.indexOf(i) > -1);

  const dispatch = useDispatch();

  const fetchIds = React.useCallback(
    () => dispatch(fetchBulkActionIdsForSearch.request()),
    [dispatch]
  );

  const clearSelectedIds = React.useCallback(
    () => dispatch(clearKOLExportIds()),
    [dispatch]
  );

  const setSelectedIds = React.useCallback(
    (ids: string[]) => dispatch(setKOLExportIds(ids)),
    [dispatch]
  );

  const cancelFetchIds = React.useCallback(
    () => dispatch(fetchBulkActionIdsForSearch.cancel()),
    [dispatch]
  );

  React.useEffect(() => {
    return () => {
      if (loading) {
        cancelFetchIds();
      }
    };
  }, [loading, cancelFetchIds]);

  const allSelected =
    allCurrentSelected && selectedIds.length > currentIds.length;
  const indeterminate =
    selectedIds.length > 0 &&
    !allCurrentSelected &&
    currentIds.some(i => selectedIds.indexOf(i) > -1);

  const followingIdCount =
    from + MaxRequestSize < SEARCH_RESULT_LIMIT
      ? MaxRequestSize
      : Math.min(resultCount, SEARCH_RESULT_LIMIT) - from;

  const actionButton = allSelected ? (
    <Button
      key="clear-selection"
      // color="secondary"
      className={classes.clearButton}
      size="small"
      onClick={() => {
        if (!loading) {
          clearSelectedIds();
        }
      }}
    >
      Clear selection
    </Button>
  ) : (
    <Button
      variant="contained"
      className={classes.selectButton}
      key="set-selection"
      color="primary"
      size="small"
      onClick={() => {
        if (!loading) {
          fetchIds();
        }
      }}
    >
      Select following {followingIdCount} profile results
    </Button>
  );

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    if (checked && !indeterminate) {
      setSelectedIds(currentIds);
    } else {
      clearSelectedIds();
    }
  }

  return (
    <>
      <Snackbar
        key="select-all-kol-snackbar"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={allCurrentSelected && followingIdCount !== currentIds.length}
      >
        <div>
          {loading && <LinearProgress color="secondary" />}
          <SnackbarContent
            action={[actionButton]}
            classes={{
              root: classes.snackbarContentRoot,
              message: classes.snackbarContentMessage
            }}
            message={
              <span id="message-id">
                {allSelected && followingIdCount !== currentIds.length
                  ? `The following ${followingIdCount} profiles are selected`
                  : `All ${currentIds.length} profiles on this page selected`}
              </span>
            }
          />
        </div>
      </Snackbar>

      <Checkbox
        checked={allCurrentSelected}
        indeterminate={indeterminate}
        color="primary"
        disabled={disabled}
        onChange={handleCheckboxChange}
      />
    </>
  );
};

export default withStyles(styles)(SelectAllKolsCheckbox);
