/* tslint:disable:cyclomatic-complexity */
import * as _ from "lodash";
import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { SearchFilterState } from "../reducers/searchFilterReducer";
import { AdvancedFilterSections } from "../actions/searchFilters";
import { SearchTypes, ActiveFilterBreakDown } from "@h1eng/interfaces";

const getState = (state: RootState) => state;
const getSearchFilterState = (state: SearchFilterState) => state;

export const getDateFilter = createSelector(
  getState,
  state => {
    return state.searchFilters.dateRangePicker;
  }
);
export const getSearchFilters = createSelector(
  getState,
  state => state.searchFilters
);

export const showAdvancedFilters = createSelector(
  getState,
  state => state.searchFilters.showAdvancedFilters
);

export const getPublicationsFilters = createSelector(
  getState,
  state => state.searchFilters.publications
);

export const getPublicationsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { publications } = state.searchFilters;

    if (publications.minCount.value !== null) {
      res += 1;
    }
    if (publications.socialMediaMinCount.value !== null) {
      res += 1;
    }

    res += publications.journal.values.length;
    res += publications.type.values.length;

    return res;
  }
);

export const getTrialsFilters = createSelector(
  getState,
  state => state.searchFilters.trials
);

export const getTagsFiltersCount = createSelector(
  getState,
  state => {
    let res = 0;
    res += state.searchFilters.tags.name.values.length;

    return res;
  }
);

export const getTrialsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { trials } = state.searchFilters;

    if (trials.minCount.value !== null) {
      res += 1;
    }

    res += trials.status.values.length;
    res += trials.phase.values.length;
    res += trials.studyType.values.length;
    res += trials.funderType.values.length;
    res += trials.sponsor.values.length;

    return res;
  }
);

export const getCongressesFilters = createSelector(
  getState,
  state => state.searchFilters.congresses
);

export const getCongressesFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { congresses } = state.searchFilters;

    if (congresses.minCount.value !== null) {
      res += 1;
    }

    res += congresses.name.values.length;
    res += congresses.type.values.length;
    res += congresses.organizerName.values.length;
    res += congresses.sessionType.values.length;

    return res;
  }
);

export const getGrantsFilters = createSelector(
  getState,
  state => state.searchFilters.grants
);

export const getGrantsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { grants } = state.searchFilters;

    if (grants.minAmount.value !== null) {
      res += 1;
    }

    res += grants.funder.values.length;

    return res;
  }
);

export const getPaymentsFilters = createSelector(
  getState,
  state => state.searchFilters.payments
);

export const getPaymentsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { payments } = state.searchFilters;

    if (payments.minAmount.value !== null) {
      res += 1;
    }

    res += payments.company.values.length;
    res += payments.drugOrDevice.values.length;
    res += payments.fundingType.values.length;

    return res;
  }
);

export const getActiveFilterBreakDown = createSelector(
  getState,
  (state): ActiveFilterBreakDown => {
    return {
      people:
        state.searchFilters.specialty.values.length +
          state.searchFilters.state.values.length +
          state.searchFilters.country.values.length +
          state.searchFilters.institutionType.values.length +
          state.searchFilters.institution.values.length >
        0,
      trails: getTrialsFiltersCount(state) > 0,
      pubs: getPublicationsFiltersCount(state) > 0,
      payments: getPaymentsFiltersCount(state) > 0,
      congress: getCongressesFiltersCount(state) > 0,
      tags: getTagsFiltersCount(state) > 0
    };
  }
);

export const getAdvancedSearchFiltersCount = createSelector(
  getState,
  state =>
    getPublicationsFiltersCount(state) +
    getTrialsFiltersCount(state) +
    getCongressesFiltersCount(state) +
    getGrantsFiltersCount(state) +
    getPaymentsFiltersCount(state)
);

export const getTotalAppliedFiltersCount = createSelector(
  getState,
  state => {
    let res: number =
      state.searchFilters.specialty.values.length +
      state.searchFilters.state.values.length +
      state.searchFilters.country.values.length +
      state.searchFilters.institution.values.length +
      getTagsFiltersCount(state) +
      state.searchFilters.institutionType.values.length;

    if (state.searchFilters.searchType !== SearchTypes.NAME) {
      res += getAdvancedSearchFiltersCount(state);
    }

    return res;
  }
);

export const getClearAllButtonDisabled = createSelector(
  getState,
  state => {
    return getTotalAppliedFiltersCount(state) === 0;
  }
);

export const getQuery = createSelector(
  getState,
  state =>
    state.searchFilters.searchType === SearchTypes.KEYWORD
      ? state.searchFilters.keywordQuery
      : state.searchFilters.nameQuery
);

export const getSortBy = createSelector(
  getState,
  state => state.searchFilters.sortBy
);

export const getSortByDisabled = createSelector(
  getState,
  state => state.searchFilters.searchType === SearchTypes.NAME
);

export const getHasQuery = createSelector(
  getState,
  state => !!getQuery(state)
);

export const getInitialOptionsLoaded = createSelector(
  getState,
  state => state.searchFilters.initialOptionsLoaded
);

export const getAdvancedFilterSectionOpen = (section: AdvancedFilterSections) =>
  createSelector(
    getSearchFilterState,
    state => state.advancedFiltersOpen.indexOf(section) > -1
  );

export const getAppliedFilters = createSelector(
  getState,
  rootState => {
    const {
      specialty,
      country,
      state,
      institution,
      institutionType,
      publications,
      trials,
      congresses,
      grants,
      payments,
      searchType,
      tags
    } = rootState.searchFilters;
    let obj: any = {
      specialty: specialty.values.join(", "),
      country: country.values.join(", "),
      state: state.values.join(", "),
      institution: institution.values.join(", "),
      institutionType: institutionType.values.join(", "),
      tags: tags.name.values.join(", ")
    };

    if (searchType !== SearchTypes.NAME) {
      obj = {
        ...obj,
        "publications.minCount": publications.minCount.value || "",
        "publications.journal": publications.journal.values.join(", "),
        "publications.type": publications.type.values.join(", "),
        "trials.minCount": publications.minCount.value || "",
        "trials.status": trials.status.values.join(", "),
        "trials.phase": trials.phase.values.join(", "),
        "trials.studyType": trials.studyType.values.join(", "),
        "trials.funderType": trials.funderType.values.join(", "),
        "trials.sponsor": trials.sponsor.values.join(", "),
        "congresses.minCount": congresses.minCount.value || "",
        "congresses.name": congresses.name.values.join(", "),
        "congresses.type": congresses.type.values.join(", "),
        "congresses.sessionType": congresses.sessionType.values.join(", "),
        "congresses.organizerName": congresses.organizerName.values.join(", "),
        "grants.minAmount": grants.minAmount.value || "",
        "grants.funder": grants.funder.values.join(", "),
        "payments.minAmount": payments.minAmount.value || "",
        "payments.company": payments.company.values.join(", "),
        "payments.drugOrDevice": payments.drugOrDevice.values.join(", "),
        "payments.fundingType": payments.fundingType.values.join(", ")
      };
    }

    Object.keys(obj).forEach(key => {
      // @ts-ignore
      if (obj[key] === "") {
        // @ts-ignore
        delete obj[key];
      }
    });

    return obj;
  }
);

export const getAppliedFiltersCount = createSelector(
  getState,
  rootState => Object.keys(getAppliedFilters(rootState)).length
);

export const getAnalyticsPayload = createSelector(
  getState,
  state => {
    const filters = JSON.stringify(getAppliedFilters(state));
    return {
      query: getQuery(state),
      filters: filters !== "{}" || undefined
    };
  }
);

export const getSearchType = createSelector(
  getState,
  state => state.searchFilters.searchType
);
