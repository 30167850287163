import { ProjectFeatures, UserProjectsPayload } from "@h1eng/interfaces";
import { ENDPOINTS } from "./api-endpoints";
import Axios from "axios";

export const getProjects = async (): Promise<UserProjectsPayload> =>
  Axios.get(`${ENDPOINTS.project}`, {
    withCredentials: true
  }).then(res => res.data);

export const fetchProjectFeatures = async (): Promise<ProjectFeatures[]> =>
  fetch(ENDPOINTS.projectFeatures, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include"
  }).then(res => res.json());

export const setActiveProject = async (
  projectId: string
): Promise<UserProjectsPayload> => {
  return fetch(ENDPOINTS.project, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ projectId })
  }).then(res => res.json());
};
