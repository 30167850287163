/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import {
  PersonCard,
  SearchTypes,
  ResultCardAffiliation
} from "@h1eng/interfaces";
import * as _ from "lodash";
import { styled } from "@h1eng/ui-components";
import { Link } from "react-router-dom";
import { RootState } from "../../../../../store/reducers";
import { getActiveAffiliationForPerson } from "../../../../../store/selectors";
import { getTotalWorksForKOL } from "../../../../../lib/getTotalWorksForKOL";
import { formatLocation } from "@h1eng/format-util";
import { TagManager } from "../../../common/TagManager";
import { OtherInstitutionsTooltip } from "./OtherInstitutionsTooltip";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

export const TitleLink = styled(Link)`
  color: #102c3c;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  margin-bottom: 6px;
  &:hover {
    color: #0aaacd;
  }
`;

export const PersonDataText = styled.div`
  color: #435d6b;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: auto;
`;

export const InfoColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 60%;
  margin-right: 24px;
  &:not(:first-of-type) {
    max-width: 20%;
  }
`;

export const InfoColumnLabel = styled.div`
  color: #435d6b;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 6px;
`;

export const Text = styled.span`
  color: #102c3c;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 9px;
`;

export const BoldText = styled(Text)`
  font-weight: bold;
`;

interface MappedStateProps {
  searchType: SearchTypes;
  affiliation: ResultCardAffiliation | null;
}

interface Props {
  person: PersonCard;
  listButton: React.ReactElement<any>;
  id: string;
}

class ResultCardContentComponent extends React.Component<
  Props & MappedStateProps
> {
  get works() {
    const { person } = this.props;
    const { scores: scoredDocuments } = person;

    let searched =
      scoredDocuments.publications.value +
      scoredDocuments.congresses.value +
      scoredDocuments.trials.value;

    if (scoredDocuments.grantsCount) {
      searched += scoredDocuments.grantsCount.value;
    }

    const total = /* person.totalWorks || */ getTotalWorksForKOL(person);

    return { total, searched };
  }

  render() {
    const { person, listButton, searchType, id, affiliation } = this.props;
    const { searched, total } = this.works;

    const locationString =
      affiliation !== null && affiliation.address !== null
        ? formatLocation(affiliation.address)
        : "";

    return (
      <Root>
        <TitleLink to={`/curie/person/${person.personId}`} id={id}>{`${
          person.firstName
        } ${(!!person.middleName && `${person.middleName} `) || ""}${
          person.lastName
        }`}</TitleLink>
        {affiliation !== null && (
          <div key={`affiliation-${affiliation.id}`}>
            <PersonDataText>{affiliation.titles.join(", ")}</PersonDataText>
            <PersonDataText>
              {affiliation.institution.name}{" "}
              <OtherInstitutionsTooltip
                affiliations={_.uniqBy(
                  person.affiliations.filter(
                    i => i.institution.name !== affiliation.institution.name
                  ),
                  i => i.institution.name
                )}
              />
            </PersonDataText>
          </div>
        )}
        {locationString !== "" && (
          <PersonDataText key={`${id}-locationString-${locationString}`}>
            {locationString}
          </PersonDataText>
        )}
        <InfoWrapper>
          {person.specialty.length > 0 && (
            <InfoColumn>
              <>
                <InfoColumnLabel>Specialty</InfoColumnLabel>
                <BoldText>{person.specialty.join(", ")}</BoldText>
              </>
            </InfoColumn>
          )}
          {total > 0 && (
            <InfoColumn>
              <>
                <InfoColumnLabel>Works</InfoColumnLabel>
                <div>
                  {searchType !== SearchTypes.NAME ? (
                    <>
                      <BoldText>{searched}/</BoldText>
                      <Text>{total}</Text>
                    </>
                  ) : (
                    <BoldText>{total}</BoldText>
                  )}
                </div>
              </>
            </InfoColumn>
          )}
          <InfoColumn>{listButton}</InfoColumn>
        </InfoWrapper>
        <TagManager
          personId={this.props.person.personId}
          tags={this.props.person.tags}
        />
      </Root>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: Props
): MappedStateProps => ({
  searchType: state.searchFilters.searchType,
  affiliation: getActiveAffiliationForPerson(ownProps.person.affiliations)(
    state
  )
});

export const ResultCardContent = connect(mapStateToProps)(
  ResultCardContentComponent
);
