/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { ClinicalTrialInterface } from "@h1eng/interfaces";
import {
  DocumentCard,
  CardSection,
  Text,
  GridSection,
  GridSectionItem,
  ExternalLink
} from "../DocumentCard";
import { TextTruncate } from "../../common/TextTruncate";

interface ClinicalTrialProps {
  trial: ClinicalTrialInterface;
}

export function formatDate(date: Date): string {
  return `${date.toLocaleString("en-us", {
    month: "short"
  })} ${date.getDate()}, ${date.getFullYear()}`;
}

export class ClinicalTrial extends React.Component<ClinicalTrialProps> {
  get gridSectionItems(): GridSectionItem[] {
    const { trial } = this.props;

    const locations = trial.locations
      .map(location => {
        let res: string = location.name;

        if (location.city && location.country) {
          res = `${res} - ${location.city}, ${location.country}`;
        }

        return res;
      })
      .filter(i => !!i);

    const dates: { [key: string]: Date | undefined } = {
      startDate: (trial.startDate && new Date(trial.startDate)) || undefined,
      primaryCompletionDate:
        (trial.primaryCompletionDate &&
          new Date(trial.primaryCompletionDate)) ||
        undefined,
      completionDate:
        (trial.completionDate && new Date(trial.completionDate)) || undefined
    };

    return [
      {
        label: "Official Title",
        value: trial.title
      },
      {
        label: "Study Type",
        value: trial.type
      },
      {
        label: `Sponsor${trial.sponsors.length > 0 ? "s" : ""}`,
        value: trial.sponsors.map(i => i.name).join(", ")
      },
      {
        label: "Estimated Enrollment",
        value: trial.enrollment ? `${trial.enrollment} participants` : undefined
      },
      {
        label: "Allocation",
        value: trial.studyDesigns
          .map(design => design.allocation)
          .filter(i => !!i)
          .join(", ")
      },
      {
        label: "Intervention Model",
        value: trial.studyDesigns
          .map(i => i.interventionalModel)
          .filter(i => !!i)
          .join(", ")
      },
      {
        label: "Masking",
        value: trial.studyDesigns
          .map(design => design.masking)
          .filter(i => !!i)
          .join(", ")
      },
      {
        label: "Primary Purpose",
        value: trial.studyDesigns
          .map(i => i.primaryPurpose)
          .filter(i => !!i)
          .join(", ")
      },
      {
        label: "Study Start Date",
        value: dates.startDate && formatDate(new Date(dates.startDate))
      },
      {
        label: "Estimated Primary Completion Date",
        value:
          dates.primaryCompletionDate &&
          formatDate(new Date(dates.primaryCompletionDate))
      },
      {
        label: "Estimated Study Completion Date",
        value:
          dates.completionDate && formatDate(new Date(dates.completionDate))
      },
      {
        label: locations.length > 1 ? "Locations" : "Location",
        value: !!locations.length && locations
      }
    ].filter(i => !!i.value);
  }

  render() {
    const { trial } = this.props;
    const { gridSectionItems } = this;

    return (
      <DocumentCard
        badgeColor="#AEFFDE"
        badgeUrl="https://d10ba5vkyum61s.cloudfront.net/img/clinical-trials-badge.svg"
      >
        <CardSection>
          <Text fullWidth bold fontSize={14}>
            {trial.briefTitle || trial.title}
          </Text>
          <Text fullWidth bold style={{ color: "rgb(115, 115, 115)" }}>
            {trial.investigators
              .map(i => `${i.firstName} ${i.lastName}`.trim())
              .filter(i => !!i)
              .join(", ")}
          </Text>
          <div style={{ marginTop: 20 }}>
            {!!trial.phaseStatus && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  Phase:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {trial.phaseStatus}
                </Text>
              </div>
            )}
            {!!trial.status && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  Status:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {trial.status}
                </Text>
              </div>
            )}
            {!!trial.conditions && trial.conditions.length && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  Conditions:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {trial.conditions.join(", ")}
                </Text>
              </div>
            )}
            {!!trial.intervention && (
              <div style={{ display: "block", width: "100%" }}>
                <Text fontSize={14} bold style={{ display: "inline" }}>
                  Intervention:&nbsp;
                </Text>
                <Text fontSize={14} style={{ display: "inline" }}>
                  {trial.intervention}
                </Text>
              </div>
            )}
            {!!trial.url && (
              <ExternalLink
                style={{ width: "100%", display: "block", marginTop: 4 }}
                href={trial.url}
              >
                Clinical Trial Source
              </ExternalLink>
            )}
          </div>
        </CardSection>
        {!!trial.summary && (
          <CardSection title="Abstract">
            <Text fontSize={14}>
              <TextTruncate lines={3}>{trial.summary}</TextTruncate>
            </Text>
          </CardSection>
        )}
        {!!trial.keywords && trial.keywords.length > 0 && (
          <CardSection title="Keywords">
            <Text fontSize={14}>{trial.keywords.join(", ")}</Text>
          </CardSection>
        )}
        {gridSectionItems.length > 0 && (
          <CardSection title="Study Design">
            <GridSection items={gridSectionItems} />
          </CardSection>
        )}
      </DocumentCard>
    );
  }
}
