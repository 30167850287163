import * as actions from "../actions/dataExports";
import { isActionOf, ActionType } from "typesafe-actions";
import { StateObservable, ActionsObservable, ofType } from "redux-observable";
import { filter, switchMap, map, catchError, takeUntil } from "rxjs/operators";
import { from, of, pipe, empty, concat } from "rxjs";
import { requestKOLExport } from "../../apis/dataExports";
import { getBulkActionPeopleIds } from "../../apis/search";
import { RootState } from "../reducers";
import { GrowlerTypes } from "@h1eng/interfaces";
import {
  createGrowler,
  searchElastic,
  setPageNum,
  selectListId,
  addToList,
  createList,
  removePeopleFromList,
  setSavedListPageNum
} from "../actions";
import { buildSearchElasticArgs } from "./searchFilters/handleSearch";

type DataExportsAction = ActionType<typeof actions>;

type ClearSelectionActions = ActionType<
  | typeof actions
  | typeof searchElastic
  | typeof setPageNum
  | typeof selectListId
  | typeof addToList
  | typeof createList
  | typeof removePeopleFromList
  | typeof setSavedListPageNum
>;

const requestBulkActionIdsEpic = (
  action$: ActionsObservable<DataExportsAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchBulkActionIdsForSearch.request)),
    switchMap(action =>
      concat(
        of(actions.setBulkActionsIdsLoading(true)),
        from(getBulkActionPeopleIds(buildSearchElasticArgs(state$.value))).pipe(
          map(actions.fetchBulkActionIdsForSearch.success),
          takeUntil(
            action$.pipe(ofType(actions.fetchBulkActionIdsForSearch.cancel))
          ),
          catchError(
            pipe(
              actions.fetchBulkActionIdsForSearch.failure,
              of
            )
          )
        )
      )
    )
  );

const unsetBulkActionsIdsLoadingEpic = (
  action$: ActionsObservable<DataExportsAction>
) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.fetchBulkActionIdsForSearch.success,
        actions.fetchBulkActionIdsForSearch.failure,
        actions.fetchBulkActionIdsForSearch.cancel
      ])
    ),
    switchMap(() => of(actions.setBulkActionsIdsLoading(false)))
  );

const handleBulkIdErrorFlow = (
  action$: ActionsObservable<DataExportsAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchBulkActionIdsForSearch.failure)),
    switchMap(() =>
      of(
        createGrowler({
          title: "Error",
          description: "There was an error. Please, try again.",
          titleIcon: "warning",
          growler: GrowlerTypes.fail
        })
      )
    )
  );

const clearSelectedFlowOnSearch = (
  action$: ActionsObservable<ClearSelectionActions>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      // @ts-ignore
      isActionOf([
        searchElastic.request,
        actions.exportKOLs.success,
        setPageNum,
        selectListId,
        addToList.success,
        createList.success,
        removePeopleFromList.success,
        setSavedListPageNum
      ])
    ),
    switchMap(() =>
      state$.value.dataExports.kols.length > 0
        ? of(actions.clearKOLExportIds())
        : empty()
    )
  );

const handleKOLExportRequestFlow = (
  action$: ActionsObservable<DataExportsAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(actions.exportKOLs.request)),
    switchMap(() =>
      from(requestKOLExport(state$.value.dataExports.kols)).pipe(
        map(actions.exportKOLs.success),
        catchError(
          pipe(
            actions.exportKOLs.failure,
            of
          )
        )
      )
    )
  );

const handleExportQueueSuccessFlow = (
  action$: ActionsObservable<DataExportsAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(actions.exportKOLs.success)),
    switchMap(() =>
      of(
        createGrowler({
          title: "Your profile export is being processed",
          description: `You profile export will be emailed to ${
            state$.value.user.user ? state$.value.user.user.email : "your email"
          } shortly.`,
          titleIcon: "check",
          growler: GrowlerTypes.success
        })
      )
    )
  );

const handleExportQueueFailureFlow = (
  action$: ActionsObservable<DataExportsAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(actions.exportKOLs.failure)),
    switchMap(() =>
      of(
        createGrowler({
          title: "Error",
          description:
            "There was an error processing your profile export. Please, try again.",
          titleIcon: "warning",
          growler: GrowlerTypes.fail
        })
      )
    )
  );

export default [
  clearSelectedFlowOnSearch,
  handleKOLExportRequestFlow,
  handleExportQueueSuccessFlow,
  handleExportQueueFailureFlow,
  requestBulkActionIdsEpic,
  handleBulkIdErrorFlow,
  unsetBulkActionsIdsLoadingEpic
];
