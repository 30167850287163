import { sortBy, groupBy } from "lodash";
import { UserProject } from "@h1eng/interfaces";
const Fuse = require("fuse.js");

const sortResults = (projects: UserProject[]) => {
  const sorted = sortBy(projects, ["clientName", "title", "description"]);

  return groupBy(sorted, i => i.clientName);
};

export function filterProjects(query: string, options: UserProject[]) {
  const config = {
    shouldSort: false,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["title", "clientName", "description"]
  };

  if (!query) return sortResults(options);

  try {
    const fuse = new Fuse(options, config);

    return sortResults(fuse.search(query));
  } catch (e) {
    console.log(e);

    return sortResults(options);
  }
}

export default filterProjects;
