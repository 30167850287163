import * as React from "react";
import { uniqBy } from "lodash";
import { TagInterface } from "@h1eng/interfaces";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tag } from "./Tag";
import { TagManagerButton } from "./TagManagerButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    rootLabel: {
      color: "#435d6b",
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "15px",
      marginBottom: 6,
      width: "100%"
    },
    tagWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: "auto",
      flexWrap: "wrap"
    }
  });

interface ComponentProps {
  personId: string;
  noLabel?: boolean;
  noButton?: boolean;
  tags: TagInterface[] | null;
}

type Props = ComponentProps & WithStyles<typeof styles>;

const TagManagerComponent: React.FunctionComponent<Props> = props => {
  const { classes, tags, noLabel = false, noButton = false } = props;

  return (
    <div className={classes.root}>
      {!noLabel && <span className={classes.rootLabel}>Tags</span>}
      <div className={classes.tagWrapper}>
        {tags === null ? (
          <div style={{ marginTop: 5 }}>
            <CircularProgress size={23} />
          </div>
        ) : (
          <>
            {uniqBy(tags, "id").map(tag => {
              return <Tag tag={tag} personId={props.personId} key={tag.id} />;
            })}
            {!noButton && (
              <TagManagerButton
                withLabel={!tags || tags.length === 0}
                personId={props.personId}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const TagManager = withStyles(styles)(TagManagerComponent);
