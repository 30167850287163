import {
  createStandardAction,
  createAsyncAction,
  ActionType
} from "typesafe-actions";
import { TagInterface } from "@h1eng/interfaces";

export interface DocumentSearchBarQueryInterface {
  personId: string;
  types: any[];
  query: string[];
}
export interface SetFilterDateDocumentSearchBar {
  filterDate: number;
  displayFilterDateString: string;
}
export const setDocumentSearchBarQuery = createStandardAction(
  "documents.searchbar.set.query"
)<DocumentSearchBarQueryInterface>();

export const setDocumentSearchBarFilterDate = createStandardAction(
  "documents.searchbar.filter.set.query"
)<SetFilterDateDocumentSearchBar>();

export const setProfilePersonId = createStandardAction("profile.personId.set")<
  string
>();

export const setProfileTags = createAsyncAction(
  "profile.tags.set.request",
  "profile.tags.set.success",
  "profile.tags.set.failure",
  "profile.tags.set.cancel"
)<string, { personId: string; tags: TagInterface[] }, Error, never>();

export const setProfileTagsLoading = createStandardAction(
  "profile.tagsLoading.set"
)<boolean>();

export type DocumentSearchBarAction =
  | ActionType<typeof setDocumentSearchBarFilterDate>
  | ActionType<typeof setDocumentSearchBarQuery>
  | ActionType<typeof setProfileTags>
  | ActionType<typeof setProfileTagsLoading>
  | ActionType<typeof setProfilePersonId>;
