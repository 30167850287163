import * as React from "react";
import { connect } from "react-redux";
import { styled } from "@h1eng/ui-components";
import { SortBy, CustomSortBy } from "@h1eng/interfaces";
import Divider from "@material-ui/core/Divider";
import { getSortBy, getSortByDisabled } from "../../../../store/selectors";
import { setSortBy, setCustomSortModalOpen } from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import { CustomSorting } from "./CustomSorting";

interface DispatchProps {
  setSortBy: (sortBy: SortBy) => void;
  openModal: (open: boolean) => void;
}

interface MappedStateProps {
  sortBy: SortBy;
  customSortByOptions: CustomSortBy[];
  disabled: boolean;
}

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
`;

const Label = styled.label`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 4px;
  margin-right: 10px;
`;

interface SortOption {
  value: SortBy;
  text: string;
}

export const sortOptions: SortOption[] = [
  {
    value: "publications",
    text: "Publication Count"
  },
  {
    value: "social media mentions",
    text: "Social Media Mentions"
  },
  {
    value: "citations",
    text: "Citation Count"
  },
  {
    value: "trials",
    text: "Clinical Trial Count"
  },
  {
    value: "congresses",
    text: "Congress Count"
  },
  // {
  //   value: "grants",
  //   text: "$ in Grants"
  // },
  {
    value: "payments",
    text: "Total Payments"
  },
  {
    value: null,
    text: "H1 Ranking"
  }
  // {
  //   value: "collaborators",
  //   text: "Collaborators Count"
  // }
];

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      "&:focus": {
        border: "none",
        outline: "none"
      }
    },
    manageItemRoot: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.light
      }
    },
    manageItemSelected: {
      backgroundColor: theme.palette.primary.light
    },
    disabled: {
      fontStyle: "italic"
    }
  });

type Props = DispatchProps & MappedStateProps & WithStyles<typeof styles>;
class SortSearchResultsComponent extends React.Component<Props> {
  get selectedOption(): SortOption {
    const selected = sortOptions.find(i => i.value === this.props.sortBy);

    if (!selected) return sortOptions[0];

    return selected;
  }

  handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "manage") {
      this.props.openModal(true);
      return;
    }
    let value: SortBy;
    const foundValue = this.props.customSortByOptions.find(
      i => i.id === event.target.value
    );
    if (foundValue) {
      value = foundValue;
    } else if (event.target.value === "relevance") {
      value = null;
    } else {
      value = event.target.value as SortBy;
    }

    this.props.setSortBy(value);
  };

  getStringValueFromSortBy = (sortBy: SortBy) => {
    let value: string;
    if (sortBy === null) {
      value = "relevance";
    } else if (typeof sortBy === "string") {
      value = sortBy;
    } else {
      value = sortBy.id;
    }

    return value;
  };

  render() {
    const { sortBy, customSortByOptions, disabled, classes } = this.props;

    const value: string = this.getStringValueFromSortBy(sortBy);

    return (
      <Root>
        <Label>Sort by:</Label>
        <div style={{ maxWidth: 165 }}>
          <FormControl fullWidth>
            <Select
              value={value}
              onChange={this.handleSelect}
              inputProps={{
                name: "sort-by",
                id: "sort-by"
              }}
              disabled={disabled}
              className={(disabled && classes.disabled) || undefined}
            >
              {sortOptions.map(opt => (
                <MenuItem
                  key={`sort-option-${opt.text}`}
                  value={this.getStringValueFromSortBy(opt.value)}
                // disabled
                >
                  {opt.text}
                </MenuItem>
              ))}
              {customSortByOptions.length > 0 && (
                <Divider tabIndex={-1} className={classes.divider} />
              )}
              {customSortByOptions.length > 0 &&
                customSortByOptions.map(i => (
                  <MenuItem
                    key={`custom-sort-option-${i.id}`}
                    value={this.getStringValueFromSortBy(i)}
                  >
                    {i.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginLeft: 15 }}>
          <CustomSorting />
        </div>
      </Root>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  sortBy: getSortBy(state),
  customSortByOptions: state.searchFilters.customSortingOptions,
  disabled: getSortByDisabled(state)
});

const mapDispatchToProps: DispatchProps = {
  setSortBy,
  openModal: setCustomSortModalOpen
};

export const SortSearchResults = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SortSearchResultsComponent));
