import { isActionOf, ActionType } from "typesafe-actions";
import { ActionsObservable, StateObservable } from "redux-observable";
import {
  filter,
  switchMap,
  map,
  catchError,
  withLatestFrom
} from "rxjs/operators";
import { from, of, pipe, concat, empty } from "rxjs";
import { RootState } from "../reducers";
import * as actions from "../actions/projects";
import {
  setQuery,
  clearSearchFilters,
  getInitialFilterOptions,
  cancelTagOptionsRequest,
  requestTagOptions,
  getListOptions,
  loadCustomSortingOptions,
  updateSearchResults,
  updateListCardsWithoutSideEffects
} from "../actions";
import { getProjects, setActiveProject } from "../../apis/projects";

type ProjectAction = ActionType<typeof actions>;

const { getProjectsForUser, setProject } = actions;

const fetchProjectsFlow = (action$: ActionsObservable<ProjectAction>) => {
  return action$.pipe(
    filter(isActionOf(getProjectsForUser.request)),
    switchMap(action =>
      from(getProjects()).pipe(
        map(getProjectsForUser.success),
        catchError(
          pipe(
            getProjectsForUser.failure,
            of
          )
        )
      )
    )
  );
};

const setProjectRequestFlow = (
  action$: ActionsObservable<ProjectAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(setProject.request)),
    switchMap(({ payload }) =>
      state$.value.projects.settingProjectLoading
        ? empty()
        : concat(
            of(updateListCardsWithoutSideEffects([])),
            of(updateSearchResults([])),
            of(actions.setProjectLoading(true)),
            from(setActiveProject(payload)).pipe(
              map(setProject.success),
              catchError(
                pipe(
                  setProject.failure,
                  of
                )
              )
            )
          )
    )
  );

const setProjectSuccessFlow = (
  action$: ActionsObservable<ProjectAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(setProject.success)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const userId = state.user.user && state.user.user.id;
      const { projectId } = state.projects;

      return concat(
        of(cancelTagOptionsRequest()),
        of(actions.setProjectLoading(false)),
        of(setQuery("")),
        of(clearSearchFilters()),
        of(requestTagOptions.request()),
        of(
          userId && projectId
            ? getListOptions.request({ userId, projectId })
            : empty()
        ),
        of(getInitialFilterOptions.request()),
        of(loadCustomSortingOptions.request())
      );
    })
  );

const setProjectFailureFlow = (action$: ActionsObservable<ProjectAction>) =>
  action$.pipe(
    filter(isActionOf(setProject.failure)),
    switchMap(() => of(actions.setProjectLoading(false)))
  );

export default [
  fetchProjectsFlow,
  setProjectRequestFlow,
  setProjectSuccessFlow,
  setProjectFailureFlow
];
