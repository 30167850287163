import * as React from "react";
import { connect } from "react-redux";
import { GenericSearchInterface } from "@h1eng/interfaces";
import { setDocumentSearchBarQuery } from "../../../store/actions";
import { userSearchSubDocumentsElastic } from "../../../store/actions/userInteractionActions";
import { getDocumentSearchBarState } from "../../../store/selectors";
import DocumentSearchBar from "./DocumentSearchBar";
import { RootState } from "../../../store/reducers";
interface ProfileDocumentSearchClassStateInterface {
  searchActive: boolean;
  pendingSearchTerm: string;
}

export interface SetDocumentSearchBarQueryInterface {
  query: string[];
  personId: string;
  types: any[];
}

interface DispatchProps {
  searchDocumentsElastic: (search: GenericSearchInterface) => void;
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
}

interface MappedStateProps {
  searchBarState: { query: string[] };
}

interface ComponentProps {
  personId: string;
  customWidth?: string;
  searchHint?: string;
  customQuery?: (personId: string, terms: string[]) => void;
}
// interface ProfileDocumentSearchOwnProps {}

type Props = DispatchProps & MappedStateProps & ComponentProps;

class ProfileDocumentSearchClass extends React.Component<
  Props,
  ProfileDocumentSearchClassStateInterface
  > {
  state = {
    searchActive: false,
    pendingSearchTerm: ""
  };

  applySearch = () => {
    if (this.state.pendingSearchTerm === "") return;
    if (
      this.props.searchBarState.query.includes(this.state.pendingSearchTerm)
    ) {
      return;
    }
    const query = [
      ...this.props.searchBarState.query,
      this.state.pendingSearchTerm
    ];
    if (this.props.customQuery) {
      this.props.customQuery(this.props.personId, query);
      this.props.setDocumentSearchBarQuery({
        query,
        personId: this.props.personId,
        types: []
      });
    } else {
      this.props.setDocumentSearchBarQuery({
        query,
        personId: this.props.personId,
        types: []
      });
    }

    this.setState({ pendingSearchTerm: "" });
  };

  _handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      this.applySearch();
    }
  };

  removeTerm = (term: string) => {
    const query = this.props.searchBarState.query.filter(
      (t: any) => t !== term
    );
    this.props.setDocumentSearchBarQuery({
      query,
      personId: this.props.personId,
      types: []
    });
    // this.setState({ searchTerms: update }, this.applySearch);
  };

  handleChange = (e: any) => {
    this.setState({ pendingSearchTerm: e.currentTarget.value });
  };

  render() {
    const width = this.props.customWidth ? this.props.customWidth : "700px"
    return (
      <div style={{ width }}>
        <div onKeyPress={this._handleKeyPress}>
          <DocumentSearchBar
            searchHint={this.props.searchHint}
            handleSearch={this.applySearch}
            handleChange={this.handleChange}
            handleKeyDown={this.handleChange}
            value={this.state.pendingSearchTerm}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  searchBarState: getDocumentSearchBarState(state)
});

const mapDispatchToProps: DispatchProps = {
  searchDocumentsElastic: userSearchSubDocumentsElastic,
  setDocumentSearchBarQuery
};

export const ProfileDocumentSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDocumentSearchClass);
