import { combineEpics } from "redux-observable";
import {
  fetchSearchFlow,
  fetchSearchDocumentsFlow,
  refetchDocuments,
  setPageNumberFlow,
  searchFailureAlertFlow,
  handlePaginationFromChangeFlow,
  // fetchTagsFromSearchResultsFlow,
  clearLoadingMessageEpic,
  searchLoadingMessageFlow,
  handleHeapAnalyticsFlow
} from "./search";
import projectsEpics from "./projects";
import { fetchCurrentUserFlow, logoutFlow } from "./auth";
import {
  getListOptionFlow,
  fetchListFlow,
  createListFlow,
  refetchListFlow,
  addToListFlow,
  deleteListFlow,
  removeFromListFlow,
  fetchCurrentListsFlow,
  unsetListsLoadingFlow,
  // createListWithGrowlerFlow,
  setSelectedListFlow,
  setSavedListPageNumFlow,
  removePeopleFromListFlow
} from "./lists";
import {
  fetchCollaborators,
  autoFetchCollaborators,
  fetchLiteProfiles,
  autoFetchCollaboratorsAfterPageUpdate,
  autoFetchCollaboratorsAfterQureyUpdate,
  autoFetchCollaboratorsAfterDateChange,
  fetchTagsForCollaborators
} from "./collaborators";
import { searchFilterEpics } from "./searchFilters";
import userInfoRequestEpics from "./userInfoRequestEpics";

import dataExportsEpics from "./dataExportEpics";

import userInteractionEpics from "./userInteractionEpics";
import tagEpics from "./tagsEpics";
import profileEpics from "./profileEpics";

const allEpics = [
  fetchSearchFlow,
  fetchListFlow,
  getListOptionFlow,
  ...projectsEpics,
  createListFlow,
  removeFromListFlow,
  fetchCurrentListsFlow,
  unsetListsLoadingFlow,
  refetchListFlow,
  addToListFlow,
  deleteListFlow,
  // createListWithGrowlerFlow,
  fetchCurrentUserFlow,
  fetchSearchDocumentsFlow,
  setPageNumberFlow,
  refetchDocuments,
  fetchCollaborators,
  autoFetchCollaborators,
  fetchLiteProfiles,
  autoFetchCollaboratorsAfterPageUpdate,
  autoFetchCollaboratorsAfterQureyUpdate,
  autoFetchCollaboratorsAfterDateChange,
  fetchTagsForCollaborators,
  setSelectedListFlow,
  searchFailureAlertFlow,
  handlePaginationFromChangeFlow,
  // fetchTagsFromSearchResultsFlow,
  clearLoadingMessageEpic,
  setSavedListPageNumFlow,
  removePeopleFromListFlow,
  searchLoadingMessageFlow,
  handleHeapAnalyticsFlow,
  ...searchFilterEpics,
  ...userInfoRequestEpics,
  ...dataExportsEpics,
  ...userInteractionEpics,
  ...tagEpics,
  ...profileEpics
  //,
  // logoutFlow
];

export const rootEpics = combineEpics(...allEpics);
