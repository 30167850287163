import { ProjectFeatures, UserProject } from "@h1eng/interfaces";
import * as actions from "../actions/projects";
import { getType, ActionType } from "typesafe-actions";

export interface ProjectState {
  projectId: string | null;
  projects: UserProject[];
  projectFeatures: ProjectFeatures | null;
  allProjectFeatures: ProjectFeatures[];
  settingProjectLoading: boolean;
}

const initialState: ProjectState = {
  projectId: null,
  projects: [],
  projectFeatures: null,
  allProjectFeatures: [],
  settingProjectLoading: false
};

type ProjectsAction = ActionType<typeof actions>;

export const projectReducer = (
  state: ProjectState = initialState,
  action: ProjectsAction
): ProjectState => {
  switch (action.type) {
    case getType(actions.setProject.success):
    case getType(actions.getProjectsForUser.success): {
      return {
        ...state,
        ...action.payload
      };
    }

    case getType(actions.setProjectLoading): {
      return {
        ...state,
        settingProjectLoading: action.payload
      };
    }

    default:
      return state;
  }
};
