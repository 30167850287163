import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/reducers";
import { setProfilePersonId } from "../../../store/actions/documentSearchBar";
import { TagManager } from "../common/TagManager";

interface Props {
  personId?: string | null;
}

const ProfileHeader: React.FC<Props> = ({ personId }: Props) => {
  const { loading, tags } = useSelector((state: RootState) => ({
    loading: state.documentSearchBar.tagsLoading,
    tags: state.documentSearchBar.tags
  }));

  const dispatch = useDispatch();

  const setPersonId = React.useCallback(
    (id: string) => dispatch(setProfilePersonId(id)),
    [dispatch]
  );

  React.useEffect(() => {
    if (personId) {
      setPersonId(personId);
    }

    return () => {
      setPersonId("");
    };
  }, [setPersonId]);

  if (!personId) return null;

  return <TagManager personId={personId} tags={loading ? null : tags} />;
};

export default ProfileHeader;
