import { connectProps } from "../MinCount";
import { RootState } from "../../../../../store/reducers";
import { setPublicationSocialMinCount } from "../../../../../store/actions";

const mapStateToProps = (state: RootState) => ({
  minCount: state.searchFilters.publications.socialMediaMinCount.value,
  label: "Social Media Minimum Count"
});

const mapDispatchToProps = {
  setMinCount: setPublicationSocialMinCount
};

export const PublicationsSocialMediaMinCount = connectProps(
  mapStateToProps,
  mapDispatchToProps
);
