import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const getProjectsState = (state: RootState) => state.projects;

export const getProjects = createSelector(
  getProjectsState,
  projects => projects.projects
);

export const getSelectedProject = createSelector(
  getProjectsState,
  projects => projects.projectId
);

export const getProjectFeatures = createSelector(
  getProjectsState,
  state => state.projectFeatures
);

export const canSwitchProjects = createSelector(
  (state: RootState) => state,
  state => {
    return (
      !state.searchResults.loading &&
      // !state.searchFilters.filterOptionsKeysLoading &&
      !state.searchFilters.filterCompletionQueryLoading &&
      !state.projects.settingProjectLoading
    );
  }
);
