import { isActionOf, ActionType } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  switchMap,
  map,
  filter,
  catchError,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
import { from, of, pipe, concat, empty } from "rxjs";
import * as profileActions from "../actions/documentSearchBar";
import { fetchTagsForPerson } from "../../apis/tags";

type ProfileAction = ActionType<typeof profileActions>;

const handleSetProfileIdFlow = (action$: ActionsObservable<ProfileAction>) =>
  action$.pipe(
    filter(isActionOf(profileActions.setProfilePersonId)),
    switchMap(({ payload }) =>
      payload
        ? of(profileActions.setProfileTags.request(payload))
        : of(profileActions.setProfileTags.cancel())
    )
  );

const handleSearchFlow = (action$: ActionsObservable<ProfileAction>) =>
  action$.pipe(
    filter(isActionOf(profileActions.setProfileTags.request)),
    switchMap(({ payload }) =>
      concat(
        of(profileActions.setProfileTagsLoading(true)),
        !payload
          ? empty()
          : from(fetchTagsForPerson(payload)).pipe(
              map(profileActions.setProfileTags.success),
              takeUntil(
                action$.pipe(ofType(profileActions.setProfileTags.cancel))
              ),
              catchError(
                pipe(
                  profileActions.setProfileTags.failure,
                  of
                )
              )
            )
      )
    )
  );

const clearLoadingMessageFlow = (action$: ActionsObservable<ProfileAction>) =>
  action$.pipe(
    filter(
      isActionOf([
        profileActions.setProfileTags.cancel,
        profileActions.setProfileTags.success,
        profileActions.setProfileTags.failure
      ])
    ),
    switchMap(() => of(profileActions.setProfileTagsLoading(false)))
  );

export default [
  handleSetProfileIdFlow,
  handleSearchFlow,
  clearLoadingMessageFlow
];
