import { isActionOf, ActionType } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  switchMap,
  map,
  filter,
  catchError,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
import { from, of, pipe, concat } from "rxjs";
import { GrowlerTypes } from "@h1eng/interfaces";
import * as tagActions from "../actions/tags";
import {
  createGrowler,
  getFilterOptionsByKey,
  clearKOLExportIds
} from "../actions";
import { RootState } from "../reducers";
import {
  fetchTagOptions,
  addPersonTag,
  removePersonTag,
  fetchTagsForPeople,
  createAndAddPersonTag,
  bulkAddTags,
  bulkRemoveTags
} from "../../apis/tags";
import {
  updateTagsInSearchResults,
  updateTagsOnProfile,
  updateTagsInSavedLists
} from "./tagEpicHelpers";

type TagAction = ActionType<typeof tagActions>;

const updateTagsInReduxStoreFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      isActionOf([
        tagActions.addTagToPerson.success,
        tagActions.removeTagFromPerson.success,
        tagActions.createAndAddTagToPerson.success,
        tagActions.bulkAddTagsToPeople.success,
        tagActions.bulkRemoveTagsFromPeople.success,
        tagActions.requestCurrentTags.success
      ])
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      concat(
        updateTagsInSearchResults(action.payload, state),
        updateTagsOnProfile(action.payload, state),
        updateTagsInSavedLists(action.payload, state)
      )
    )
  );

const getTagSearchOptionsFlow: Epic<TagAction, any, any> = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.requestTagOptions.request)),
    switchMap(() =>
      concat(
        of(tagActions.setTagOptionsLoading(true)),
        from(fetchTagOptions()).pipe(
          map(tagActions.requestTagOptions.success),
          takeUntil(action$.pipe(ofType(tagActions.cancelTagOptionsRequest))),
          catchError(
            pipe(
              tagActions.requestTagOptions.failure,
              of
            )
          )
        )
      )
    )
  );

const handleOptionsLoadingStateFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      isActionOf([
        tagActions.requestTagOptions.success,
        tagActions.requestTagOptions.failure
      ])
    ),
    switchMap(() => of(tagActions.setTagOptionsLoading(false)))
  );

const addTagFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.addTagToPerson.request)),
    switchMap(({ payload }) =>
      from(addPersonTag(payload)).pipe(
        map(tagActions.addTagToPerson.success),
        catchError(
          pipe(
            tagActions.addTagToPerson.failure,
            of
          )
        )
      )
    )
  );

const createAndAddTagFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.createAndAddTagToPerson.request)),
    switchMap(({ payload }) =>
      from(createAndAddPersonTag(payload)).pipe(
        map(tagActions.createAndAddTagToPerson.success),
        catchError(
          pipe(
            tagActions.createAndAddTagToPerson.failure,
            of
          )
        )
      )
    )
  );

const removeTagFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.removeTagFromPerson.request)),
    switchMap(({ payload }) =>
      from(removePersonTag(payload)).pipe(
        map(tagActions.removeTagFromPerson.success),
        catchError(
          pipe(
            tagActions.removeTagFromPerson.failure,
            of
          )
        )
      )
    )
  );

const fetchCurrentTagsFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.requestCurrentTags.request)),
    switchMap(({ payload }) =>
      from(fetchTagsForPeople(payload)).pipe(
        map(tagActions.requestCurrentTags.success),
        catchError(
          pipe(
            tagActions.requestCurrentTags.failure,
            of
          )
        )
      )
    )
  );

const updateCurrentTagsForPersonFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.appendCurrentTagsForPeople.request)),
    switchMap(({ payload }) =>
      from(
        fetchTagsForPeople(Array.isArray(payload) ? payload : [payload])
      ).pipe(
        map(tagActions.appendCurrentTagsForPeople.success),
        catchError(
          pipe(
            tagActions.appendCurrentTagsForPeople.failure,
            of
          )
        )
      )
    )
  );

const updateCurrentTagsForPersonOnModifyFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      isActionOf([
        tagActions.addTagToPerson.success,
        tagActions.removeTagFromPerson.success,
        tagActions.createAndAddTagToPerson.success
      ])
    ),
    switchMap(({ payload }) =>
      concat(
        of(tagActions.requestTagOptions.request()),
        of(
          tagActions.appendCurrentTagsForPeople.success({
            [payload.personId]: payload.tags
          })
        ),
        of(getFilterOptionsByKey.request("tags"))
      )
    )
  );

const updateTagOptionsOnAddFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([tagActions.createAndAddTagToPerson.success])),
    switchMap(({ payload }) =>
      concat(of(tagActions.requestTagOptions.request()))
    )
  );

const bulkAddTagsFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.bulkAddTagsToPeople.request)),
    switchMap(({ payload }) =>
      from(bulkAddTags(payload)).pipe(
        map(tagActions.bulkAddTagsToPeople.success),
        catchError(
          pipe(
            tagActions.bulkAddTagsToPeople.failure,
            of
          )
        )
      )
    )
  );

const bulkRemoveTagsFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(tagActions.bulkRemoveTagsFromPeople.request)),
    switchMap(({ payload }) =>
      from(bulkRemoveTags(payload)).pipe(
        map(tagActions.bulkRemoveTagsFromPeople.success),
        catchError(
          pipe(
            tagActions.bulkRemoveTagsFromPeople.failure,
            of
          )
        )
      )
    )
  );

const handleBulkTagsSuccessFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      isActionOf([
        tagActions.bulkAddTagsToPeople.success,
        tagActions.bulkRemoveTagsFromPeople.success
      ])
    ),
    switchMap(({ payload }) =>
      concat(
        of(
          createGrowler({
            title: "Tags Updated",
            description: `Successfully updated tags for ${
              Object.keys(payload).length
            } people`,
            titleIcon: "check",
            growler: GrowlerTypes.success
          })
        ),
        of(tagActions.appendCurrentTagsForPeople.success(payload)),
        of(tagActions.requestTagOptions.request()),
        of(getFilterOptionsByKey.request("tags")),
        of(clearKOLExportIds())
      )
    )
  );

const handleErrorsFlow = (
  action$: ActionsObservable<TagAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      // @ts-ignore
      isActionOf([
        tagActions.requestTagOptions.failure,
        tagActions.addTagToPerson.failure,
        tagActions.removeTagFromPerson.failure,
        tagActions.requestCurrentTags.failure,
        tagActions.appendCurrentTagsForPeople.failure,
        tagActions.bulkAddTagsToPeople.failure,
        tagActions.bulkRemoveTagsFromPeople.failure
      ])
    ),
    switchMap(() =>
      of(
        createGrowler({
          title: "Tags Error",
          description: "There was an error making a tags request",
          titleIcon: "warning",
          growler: GrowlerTypes.fail
        })
      )
    )
  );

export default [
  getTagSearchOptionsFlow,
  handleOptionsLoadingStateFlow,
  addTagFlow,
  removeTagFlow,
  createAndAddTagFlow,
  fetchCurrentTagsFlow,
  updateCurrentTagsForPersonFlow,
  handleErrorsFlow,
  updateCurrentTagsForPersonOnModifyFlow,
  updateTagOptionsOnAddFlow,
  bulkAddTagsFlow,
  bulkRemoveTagsFlow,
  handleBulkTagsSuccessFlow,
  updateTagsInReduxStoreFlow
];
