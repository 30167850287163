// tslint:disable:cyclomatic-complexity
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate,
  DocumentSearchBarAction,
  setProfileTags,
  setProfileTagsLoading,
  setProfilePersonId
} from "../actions/documentSearchBar";
import { getType } from "typesafe-actions";
import { TagInterface } from "@h1eng/interfaces";

export interface SearchBarQuery {
  query: string[];
  personId: string;
  types: any[];
  filterDate: number;
  filterDateText: string;
  loading: boolean;
  tags: TagInterface[];
  tagsLoading: boolean;
}

const initialState: SearchBarQuery = {
  query: [],
  personId: "",
  types: [],
  filterDate: new Date().setFullYear(new Date().getFullYear() - 100),
  filterDateText: "All time",
  loading: false,
  tags: [],
  tagsLoading: false
};

export const searchBarReducer = (
  state: SearchBarQuery = initialState,
  action: DocumentSearchBarAction
): SearchBarQuery => {
  switch (action.type) {
    case getType(setDocumentSearchBarQuery): {
      return {
        ...state,
        ...action.payload
      };
    }
    case getType(setDocumentSearchBarFilterDate): {
      return {
        ...state,
        filterDate: action.payload.filterDate,
        filterDateText: action.payload.displayFilterDateString
      };
    }

    case getType(setProfilePersonId): {
      return {
        ...state,
        personId: action.payload
      };
    }

    case getType(setProfileTags.success): {
      return {
        ...state,
        tags: action.payload.tags
      };
    }

    case getType(setProfileTagsLoading): {
      return {
        ...state,
        tagsLoading: action.payload
      };
    }

    default:
      return state;
  }
};
