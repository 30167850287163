import { getType } from "typesafe-actions";
import {
  searchCollaborators,
  CollaboratorsAction,
  setPage,
  getLiteProfileAction,
  setPersonId
} from "../actions/collaborators";
import { CollaboratorsDetails, LiteProfile } from "@h1eng/interfaces";
export interface CollaboratorState extends CollaboratorsDetails {
  loading: boolean;
  page: number;
  size: number;
  liteProfiles: LiteProfile[];
  loadingLiteProfile: boolean;
  loadingDetails: boolean;
  personId: string;
}

export const collaboratorsInitialState = {
  collaborators: [],
  loading: true,
  page: 0,
  size: 15,
  liteProfiles: [],
  sortedIds: [],
  loadingLiteProfile: true,
  loadingDetails: true,
  personId: ""
} as CollaboratorState;

export const CollaboratorReducer = (
  state: CollaboratorState = collaboratorsInitialState,
  action: CollaboratorsAction
): CollaboratorState => {
  switch (action.type) {
    case getType(searchCollaborators.success): {
      return {
        ...state,
        ...action.payload,
        loading: false,
        loadingDetails: true
      };
    }
    case getType(searchCollaborators.request): {
      return {
        ...state,
        page: 0,
        loading: true
      };
    }
    case getType(getLiteProfileAction.success): {
      return {
        ...state,
        loadingLiteProfile: false,
        loadingDetails: false,
        liteProfiles: action.payload.payload
      };
    }
    case getType(getLiteProfileAction.request): {
      return {
        ...state,
        loadingLiteProfile: true
      };
    }
    case getType(setPage): {
      return {
        ...state,
        page: action.payload
      };
    }
    case getType(setPersonId): {
      return {
        ...state,
        personId: action.payload
      };
    }

    default:
      return state;
  }
};
