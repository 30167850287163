import * as React from "react";
import { styled } from "@h1eng/ui-components";
import {
  PercentageIndicator,
  PercentageIndicatorProps,
  PercentageIndicatorWrapper
} from "../../../../common/PercentageIndicator";
import { ScoredDocumentData } from "@h1eng/interfaces";
import { colors } from "../../colors";
import { formatStats } from "@h1eng/format-util";
import { TooltipContent } from "./TooltipConent";
// @ts-ignore
import numeral from "numeral";

const Root = styled.div`
  width: 225px;
`;

interface ResultPercentageIndicatorsProps {
  scoredDocuments: ScoredDocumentData;
  personId: string;
}

type FormatPrependOpt = boolean | string;

export class ResultPercentageIndicators extends React.Component<
  ResultPercentageIndicatorsProps
  > {
  tooltipProps = {
    backgroundColor: "#FAFAFA",
    caretSize: 10,
    style: {
      backgroundColor: "#FAFAFA",
      boxShadow: "0 0 8px 0 rgba(0,0,0,0.12), 0 8px 8px 0 rgba(0,0,0,0.24)",
      bottom: 22
    }
  };

  formatStats = (prepend: FormatPrependOpt) => (val: number) => {
    if (!prepend) return formatStats(val);

    const char = prepend === true ? "$" : prepend;

    return `${char}${formatStats(val)}`;
  };

  get stats(): PercentageIndicatorProps[] {
    const { scoredDocuments, personId } = this.props;

    const linkRoot = `/curie/person/${personId}`;

    const res = [
      {
        label: "Publications",
        value: scoredDocuments.publications.value,
        percentile: scoredDocuments.publications.percentile,
        total: scoredDocuments.publications.maxValue,
        color: colors.publications,
        formatter: this.formatStats(false),
        statDescriptor: "publication count",
        link: true,
        to: `${linkRoot}/publications`
      },
      {
        label: "Citations",
        value: scoredDocuments.citations.value,
        percentile: scoredDocuments.citations.percentile,
        total: scoredDocuments.citations.maxValue,
        color: colors.citations,
        formatter: (val: number) => numeral(val).format("0[.]00a"),
        statDescriptor: "citation count",
        link: true,
        to: `${linkRoot}/publications`
      },
      {
        label: "Social Media Mentions",
        value: scoredDocuments["social media mentions"].value,
        percentile: scoredDocuments["social media mentions"].percentile,
        total: scoredDocuments["social media mentions"].maxValue,
        color: colors.socialMediaMentions,
        formatter: this.formatStats(false),
        statDescriptor: "publication social media mentions",
        link: true,
        to: `${linkRoot}/publications`
      },
      {
        label: "Collaborators",
        value: scoredDocuments.collaborators.value,
        percentile: scoredDocuments.collaborators.percentile,
        total: scoredDocuments.collaborators.maxValue,
        color: colors.collaborators,
        formatter: this.formatStats(false),
        statDescriptor: "Collaborators",
        link: true,
        to: `${linkRoot}/collaborators`,
        valueFootnote:
          "Not all collaborators are available in your therapeutic areas"
      },
      {
        label: "Clinical Trials",
        value: scoredDocuments.trials.value,
        percentile: scoredDocuments.trials.percentile,
        total: scoredDocuments.trials.maxValue,
        color: colors.trials,
        formatter: this.formatStats(false),
        statDescriptor: "clinical trials count",
        link: true,
        to: `${linkRoot}/clinical-trials`
      },
      {
        label: "Congresses",
        value: scoredDocuments.congresses.value,
        percentile: scoredDocuments.congresses.percentile,
        total: scoredDocuments.congresses.maxValue,
        color: colors.congresses,
        formatter: this.formatStats(false),
        statDescriptor: "congresses count",
        link: true,
        to: `${linkRoot}/congresses`
      },
      {
        label: "Payments",
        value: scoredDocuments.payments.value,
        percentile: scoredDocuments.payments.percentile,
        total: scoredDocuments.payments.maxValue,
        color: colors.payments,
        formatter: this.formatStats("$"),
        statDescriptor: "payments received",
        link: true,
        to: `${linkRoot}/payments`
      }
    ];

    if (scoredDocuments.grants) {
      res.push({
        label: "Grants",
        value: scoredDocuments.grants.value,
        percentile: scoredDocuments.grants.percentile,
        total: scoredDocuments.grants.maxValue,
        color: colors.grants,
        formatter: this.formatStats("$"),
        statDescriptor: "grants awarded",
        link: true,
        to: `${linkRoot}/grants`
      });
    }

    return res
      .filter(i => !!i.value)
      .map(stat => ({
        ...stat,
        tooltipProps: this.tooltipProps,
        tooltipContent: <TooltipContent {...stat} />
      }));
  }

  render() {
    const { stats } = this;

    return (
      <Root>
        <PercentageIndicatorWrapper>
          {stats.map((stat, i) => (
            <PercentageIndicator
              key={`stat-${i}-${stat.label}-${stat.value}-${stat.total}`}
              {...stat}
            />
          ))}
        </PercentageIndicatorWrapper>
      </Root>
    );
  }
}
