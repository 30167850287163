/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { UserProject } from "@h1eng/interfaces";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import classNames from "classnames";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import { RootState } from "../../../store/reducers";
import { setProject } from "../../../store/actions/projects";
import { canSwitchProjects } from "../../../store/selectors";
import filterProjects from "./lib/filterProjects";

interface MappedStateProps {
  projects: UserProject[];
  selectedProjectId: string | null;
  loading: boolean;
}

interface DispatchProps {
  setActiveProject: (projectId: string) => void;
}

export type ConnectedProps = MappedStateProps & DispatchProps;

export const projectSelectorStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    label: {
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: theme.spacing.unit,
      cursor: "default"
    },
    textFieldWrapper: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12
    },
    menuItem: {
      height: "auto",
      whiteSpace: "unset",
      wordBreak: "break-word"
    },
    listSubheader: {
      cursor: "default",
      lineHeight: "24px",
      color: "#333"
    },
    listItemText: {
      "&:first-child": {
        paddingLeft: theme.spacing.unit * 4
      }
    },
    buttonIcon: {
      transition: theme.transitions.create("transform", { duration: 200 })
    },
    openButtonIcon: {
      transform: "rotate(180deg)"
    }
  });

type Props = MappedStateProps &
  DispatchProps &
  RouteComponentProps &
  WithStyles<typeof projectSelectorStyles>;

type AnchorEl = EventTarget & HTMLElement;

const MAX_HEIGHT = 512;

export const ProjectSelectorComponent: React.FC<Props> = ({
  selectedProjectId,
  projects,
  loading,
  setActiveProject,
  classes,
  history
}) => {
  const activeProject = projects.find(i => i.id === selectedProjectId);

  if (selectedProjectId === null || !activeProject || projects.length <= 1) {
    return null;
  }

  const [anchorEl, setAnchorEl] = React.useState<AnchorEl | null>(null);
  const [inputVal, setInputVal] = React.useState("");

  const handleOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setInputVal("");
  };

  const handleSelect = (newVal: string) => () => {
    if (newVal === selectedProjectId) {
      handleClose();
      return;
    }

    setActiveProject(newVal);
    handleClose();
    history.push("/curie/search");
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { value } = e.target;
    setInputVal(value);
  };

  const projectOptions = filterProjects(inputVal, projects);
  const projectClients = Object.keys(projectOptions);

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.label}>
        Project:
      </Typography>
      <div>
        <Button onClick={handleOpen} disabled={loading}>
          {activeProject.title}
          <ArrowDropDownIcon
            className={classNames(
              classes.buttonIcon,
              Boolean(anchorEl) && classes.openButtonIcon
            )}
          />
        </Button>
        <Popper
          open={Boolean(anchorEl) && !loading}
          anchorEl={anchorEl}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                maxHeight: MAX_HEIGHT,
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper style={{ maxHeight: MAX_HEIGHT }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div style={{ maxHeight: MAX_HEIGHT, overflow: "auto" }}>
                    <div className={classes.textFieldWrapper}>
                      <TextField
                        onChange={handleInputChange}
                        value={inputVal}
                        fullWidth
                        label={<em>Search Projects...</em>}
                        onBlur={() => setInputVal("")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ pointerEvents: "none" }}
                            >
                              <SearchIcon color="primary" />
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    <MenuList component="nav">
                      {projectClients.map(clientName => {
                        const options = projectOptions[clientName];

                        if (!options.length) return null;

                        return (
                          <div key={`${clientName}`}>
                            <ListSubheader
                              disableSticky
                              className={classes.listSubheader}
                            >
                              {clientName}
                            </ListSubheader>
                            {options.map(project => (
                              <MenuItem
                                dense
                                key={`${clientName}-${project.id}`}
                                selected={project.id === selectedProjectId}
                                onClick={handleSelect(project.id)}
                                className={classes.menuItem}
                              >
                                <ListItemText
                                  className={classes.listItemText}
                                  primary={project.title}
                                  secondary={project.description || undefined}
                                />
                              </MenuItem>
                            ))}
                          </div>
                        );
                      })}
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  projects: state.projects.projects,
  selectedProjectId: state.projects.projectId,
  loading: !canSwitchProjects(state)
});

const mapDispatchToProps: DispatchProps = {
  setActiveProject: setProject.request
};

export const ProjectSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(projectSelectorStyles)(ProjectSelectorComponent)));

export default ProjectSelector;
