import { RootState } from "../reducers";
import { createSelector } from "reselect";
import * as _ from "lodash";
import { ResultCardAffiliation } from "@h1eng/interfaces";

const getState = (state: RootState) => state;

export const getSearchResultsState = (state: RootState) => state.searchResults;

// export const getPersonIds = createSelector(
//   getSearchResultsState,
//   results => results.currentResultSetPersonIds
// );

export const getTotalHits = createSelector(
  getSearchResultsState,
  results => results.total
);

export const isLoadingPeople = createSelector(
  getSearchResultsState,
  results => {
    return results.loading;
  }
);
export const getSearchView = createSelector(
  getSearchResultsState,
  e => e.searchView
);

export const getPageNum = createSelector(
  getSearchResultsState,
  results => {
    return results.from === 0 ? 0 : results.from / results.pageSize;
  }
);

export const getPageSize = createSelector(
  getSearchResultsState,
  results => {
    return results.pageSize;
  }
);

export const getDocumentSearch = createSelector(
  getSearchResultsState,
  results => results.documentSearchResults
);

/**
 * The actual number of returned results
 */
export const getSearchResultsCount = createSelector(
  getState,
  state => state.searchResults.total
);

export const getDocumentSearchLoading = createSelector(
  getSearchResultsState,
  res => res.documentSearchLoading
);

export const getScoredPersons = createSelector(
  getState,
  (state: RootState) => {
    return state.searchResults.results;
  }
);

export const getCards = getScoredPersons;

export const getCurrentPersonIds = createSelector(
  getState,
  (state: RootState): string[] => {
    return (state.searchResults.results || []).map(i => i.personId);
  }
);

export const getDisplayLoadingMessage = createSelector(
  getState,
  (state: RootState) => state.searchResults.displayLoadingMessage
);

function getInstitutionName(a: string | ResultCardAffiliation) {
  if (typeof a === "string") {
    return a.trim().toLowerCase();
  }

  if (a && a.institution && a.institution.name) {
    return a.institution.name.trim().toLowerCase();
  }

  return false;
}

export const getActiveAffiliationForPerson = (
  affiliations: ResultCardAffiliation[]
) =>
  createSelector(
    getState,
    (state): ResultCardAffiliation | null => {
      if (affiliations.length === 0) {
        return null;
      }

      const selectedInstitutions = state.searchFilters.institution.values;
      if (selectedInstitutions.length === 0) {
        return affiliations[0];
      }

      const intersection = _.intersectionWith(
        affiliations,
        selectedInstitutions,
        (a, b) => {
          const name1 = getInstitutionName(a);
          const name2 = getInstitutionName(b);

          if (name1 === false && name2 === false) {
            return false;
          }
          return name1 === name2;
        }
      );

      if (intersection.length > 0) {
        return intersection[0];
      }

      return affiliations[0];
    }
  );
