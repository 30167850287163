import * as actions from "../actions/dataExports";
import { getType, ActionType } from "typesafe-actions";

export interface DataExportsState {
  /**
   * An array of KOL pks
   */
  kols: string[];
  bulkActionsIdsLoading: boolean;
}

export const dataExportsInitialState: DataExportsState = {
  kols: [],
  bulkActionsIdsLoading: false
};

type DataExportsAction = ActionType<typeof actions>;

export const dataExportsReducer = (
  state: DataExportsState = dataExportsInitialState,
  action: DataExportsAction
): DataExportsState => {
  switch (action.type) {
    case getType(actions.fetchBulkActionIdsForSearch.success):
    case getType(actions.setKOLExportIds): {
      return {
        ...state,
        kols: action.payload
      };
    }

    case getType(actions.clearKOLExportIds): {
      return {
        ...state,
        kols: []
      };
    }

    case getType(actions.setBulkActionsIdsLoading): {
      return {
        ...state,
        bulkActionsIdsLoading: action.payload
      };
    }
  }

  return state;
};
