/* tslint:disable:cyclomatic-complexity */
import { getType } from "typesafe-actions";
import {
  FilterInterface,
  FilterTypesEnum,
  SearchFilterOptions,
  IndexTypes,
  SortBy,
  CustomSortBy,
  SearchTypes,
  DateRangeFilter
} from "@h1eng/interfaces";
import { mapInitialSearchFilterOptionsToState } from "../lib";
import {
  getInitialFilterOptions,
  setInitialOptionsLoaded,
  setFilterCompletionQueryLoading,
  setAdvancedFilterSectionsOpen,
  toggleAdvancedFilterSection,
  resetInitialOptions,
  setSortBy,
  setNameQuery,
  setKeywordQuery,
  setSpecialtyValues,
  requestSpecialtyOptions,
  setSpecialtyQuery,
  setCountryQuery,
  setCountryValues,
  requestCountryOptions,
  setStateQuery,
  setStateValues,
  requestStateOptions,
  setInstitutionQuery,
  setInstitutionValues,
  requestInstitutionOptions,
  clearSearchFilters,
  SearchFilterAction,
  setShowAdvancedFilters,
  setTagNameQuery,
  setTagNameValues,
  requestTagNameOptions,
  setPublicationMinCount,
  setPublicationJournalQuery,
  setPublicationJournalValues,
  requestPublicationJournalOptions,
  setPublicationTypeQuery,
  setPublicationTypeValues,
  requestPublicationTypeOptions,
  setTrialMinCount,
  setTrialStatusQuery,
  setTrialStatusValues,
  requestTrialStatusOptions,
  setTrialPhaseQuery,
  setTrialPhaseValues,
  requestTrialPhaseOptions,
  setTrialStudyTypeQuery,
  setTrialStudyTypeValues,
  requestTrialStudyTypeOptions,
  setTrialFunderTypeQuery,
  setTrialFunderTypeValues,
  requestTrialFunderTypeOptions,
  setTrialSponsorQuery,
  setTrialSponsorValues,
  requestTrialSponsorOptions,
  setCongressMinCount,
  setCongressNameQuery,
  setCongressNameValues,
  requestCongressNameOptions,
  setCongressOrganizerNameQuery,
  setCongressOrganizerNameValues,
  requestCongressOrganizerNameOptions,
  setCongressSessionTypeQuery,
  setCongressSessionTypeValues,
  requestCongressSessionTypeOptions,
  setCongressTypeValues,
  requestCongressTypeOptions,
  setGrantMinAmount,
  setGrantFunderQuery,
  setGrantFunderValues,
  requestGrantFunderOptions,
  setPaymentMinAmount,
  setPaymentCompanyQuery,
  setPaymentCompanyValues,
  requestPaymentCompanyOptions,
  setPaymentDrugOrDeviceQuery,
  setPaymentDrugOrDeviceValues,
  requestPaymentDrugOrDeviceOptions,
  setPaymentFundingTypeQuery,
  setPaymentFundingTypeValues,
  requestPaymentFundingTypeOptions,
  setCustomSortModalOpen,
  setCustomSortingOptionsLoaded,
  loadCustomSortingOptions,
  createCustomSortingOption,
  editCustomSortingOption,
  deleteCustomSortingOption,
  setSearchType,
  AdvancedFilterSections,
  setDateRangeFilterMin,
  setDateRangeFilterMax,
  setInstitutionTypeValues,
  setFilterOptionLoading,
  clearFilterOptionLoading,
  setPublicationSocialMinCount
} from "../actions/searchFilters";

export interface SearchFilterState extends FilterInterface {
  showAdvancedFilters: boolean;
  advancedFiltersOpen: AdvancedFilterSections[];
  initialOptionsLoaded: boolean;
  filterCompletionQueryLoading: boolean;
  filterOptionsKeysLoading: string[];
  initialOptions: SearchFilterOptions;
  sortBy: SortBy;
  customSortModalOpen: boolean;
  customSortingOptions: CustomSortBy[];
  customSortingOptionsLoaded: boolean;
  keywordQuery: string;
  nameQuery: string;
  searchType: SearchTypes;
  dateRangePicker: DateRangeFilter;
}
const dateYear = new Date().getFullYear();

export const defaultSortByOption: SortBy = "publications";

export const searchFilterInitialState: SearchFilterState = {
  showAdvancedFilters: false,
  advancedFiltersOpen: [],
  keywordQuery: "",
  nameQuery: "",
  searchType: SearchTypes.KEYWORD,
  sortBy: defaultSortByOption,
  customSortModalOpen: false,
  customSortingOptionsLoaded: false,
  customSortingOptions: [],
  initialOptionsLoaded: false,
  filterCompletionQueryLoading: false,
  filterOptionsKeysLoading: [],
  dateRangePicker: { max: dateYear, min: 1944, active: true },
  initialOptions: {
    specialty: [],
    country: [],
    state: [],
    institution: [],
    institutionType: [],
    tags: {
      name: []
    },
    publications: {
      journal: [],
      type: []
    },
    trials: {
      status: [],
      phase: [],
      studyType: [],
      funderType: [],
      sponsor: []
    },
    congresses: {
      name: [],
      type: [],
      organizerName: [],
      sessionType: []
    },
    grants: {
      funder: []
    },
    payments: {
      company: [],
      drugOrDevice: [],
      fundingType: []
    }
  },
  specialty: {
    keyName: "specialty",
    query: "",
    type: FilterTypesEnum.therapeuticArea,
    path: "specialty",
    index: IndexTypes.person,
    options: [],
    values: [],
    mustNotValue: "No Specialty"
  },
  country: {
    keyName: "country",
    query: "",
    type: FilterTypesEnum.locationCountry,
    path: "locations.country",
    index: IndexTypes.person,
    options: [],
    values: []
  },
  state: {
    keyName: "state",
    query: "",
    type: FilterTypesEnum.locationState,
    path: "locations.state",
    index: IndexTypes.person,
    options: [],
    values: []
  },
  institution: {
    keyName: "institution",
    query: "",
    type: FilterTypesEnum.institution,
    path: "institutions",
    index: IndexTypes.person,
    options: [],
    values: []
  },
  institutionType: {
    keyName: "institutionType",
    type: FilterTypesEnum.institutionType,
    path: "institutions",
    index: IndexTypes.person,
    options: ["NCCN", "NCI", "ATC"],
    values: []
  },
  tags: {
    name: {
      keyName: "tags.name",
      query: "",
      type: FilterTypesEnum.tagName,
      path: "name",
      index: IndexTypes.tag,
      options: [],
      values: []
    }
  },
  publications: {
    socialMediaMinCount: {
      value: null,
      type: FilterTypesEnum.publicationsSocialMediaCount,
      index: IndexTypes.publication,
      // @TODO: add me
      path: "minCount"
    },
    minCount: {
      value: null,
      type: FilterTypesEnum.publicationMinCount,
      index: IndexTypes.publication,
      // @TODO: add me
      path: "minCount"
    },
    journal: {
      keyName: "publications.journal",
      query: "",
      type: FilterTypesEnum.publicationJournal,
      path: "journal",
      index: IndexTypes.publication,
      options: [],
      values: []
    },
    type: {
      keyName: "publications.type",
      query: "",
      type: FilterTypesEnum.publicationType,
      path: "publicationTypes",
      index: IndexTypes.publication,
      options: [],
      values: []
    }
  },
  trials: {
    minCount: {
      value: null,
      type: FilterTypesEnum.trialMinCount,
      index: IndexTypes.trial,
      // @TODO: add me
      path: "minCount"
    },
    status: {
      keyName: "trials.status",
      query: "",
      type: FilterTypesEnum.trialStatus,
      path: "status.keyword",
      index: IndexTypes.trial,
      options: [],
      values: []
    },
    phase: {
      keyName: "trials.phase",
      query: "",
      type: FilterTypesEnum.trialPhase,
      path: "phase.keyword",
      index: IndexTypes.trial,
      options: [],
      values: []
    },
    studyType: {
      keyName: "trials.studyType",
      query: "",
      type: FilterTypesEnum.trialStudyType,
      path: "type.keyword",
      index: IndexTypes.trial,
      options: [],
      values: []
    },
    /**
     * @TODO: looks like this needs added
     */
    funderType: {
      keyName: "trials.funderType",
      query: "",
      type: FilterTypesEnum.trialFunderType,
      path: "funderType",
      index: IndexTypes.trial,
      options: [],
      values: []
    },
    sponsor: {
      keyName: "trials.sponsor",
      query: "",
      type: FilterTypesEnum.trialSponsor,
      path: "sponsors.name",
      index: IndexTypes.trial,
      options: [],
      values: []
    }
  },
  congresses: {
    minCount: {
      value: null,
      type: FilterTypesEnum.congressMinCount,
      index: IndexTypes.congress,
      // @TODO: add me
      path: "minCount"
    },
    name: {
      keyName: "congresses.name",
      query: "",
      type: FilterTypesEnum.congressName,
      path: "conferenceName",
      index: IndexTypes.congress,
      options: [],
      values: []
    },
    type: {
      keyName: "congresses.type",
      type: FilterTypesEnum.congressType,
      path: "type",
      index: IndexTypes.congress,
      options: [],
      values: []
    },
    organizerName: {
      keyName: "congresses.organizerName",
      query: "",
      type: FilterTypesEnum.congressOrganizerName,
      path: "organizerName",
      index: IndexTypes.congress,
      options: [],
      values: []
    },
    sessionType: {
      keyName: "congresses.sessionType",
      query: "",
      type: FilterTypesEnum.congressSessionType,
      path: "sessionType",
      index: IndexTypes.congress,
      options: [],
      values: []
    }
  },
  grants: {
    minAmount: {
      value: null,
      type: FilterTypesEnum.grantMinAmount,
      index: IndexTypes.grant,
      path: "totalFunds"
    },
    funder: {
      keyName: "grants.funder",
      query: "",
      type: FilterTypesEnum.grantFunder,
      path: "funder",
      index: IndexTypes.grant,
      options: [],
      values: []
    }
  },
  payments: {
    minAmount: {
      value: null,
      type: FilterTypesEnum.paymentMinAmount,
      index: IndexTypes.payment,
      path: "amount"
    },
    company: {
      keyName: "payments.company",
      query: "",
      type: FilterTypesEnum.paymentCompany,
      path: "payerCompany",
      index: IndexTypes.payment,
      options: [],
      values: []
    },
    drugOrDevice: {
      keyName: "payments.drugOrDevice",
      query: "",
      type: FilterTypesEnum.paymentDrugOrDevice,
      path: "associatedDrug",
      index: IndexTypes.payment,
      options: [],
      values: []
    },
    fundingType: {
      keyName: "payments.fundingType",
      query: "",
      type: FilterTypesEnum.paymentNature,
      path: "natureOfPayment",
      index: IndexTypes.payment,
      options: [],
      values: []
    }
  }
};

export const searchFilterReducer = (
  state: SearchFilterState = searchFilterInitialState,
  action: SearchFilterAction
): SearchFilterState => {
  switch (action.type) {
    case getType(setDateRangeFilterMin): {
      return {
        ...state,
        dateRangePicker: {
          ...state.dateRangePicker,
          min: action.payload,
          max:
            action.payload > state.dateRangePicker.max
              ? action.payload
              : state.dateRangePicker.max
        }
      };
    }
    case getType(setDateRangeFilterMax): {
      return {
        ...state,
        dateRangePicker: { ...state.dateRangePicker, max: action.payload }
      };
    }
    // case getType(setDateRangeActive): {
    //   return {
    //     ...state,
    //     dateRangePicker: { ...state.dateRangePicker, active: action.payload }
    //   };
    // }
    // General
    case getType(setSortBy): {
      return {
        ...state,
        sortBy: action.payload
      };
    }

    case getType(setCustomSortModalOpen): {
      return {
        ...state,
        customSortModalOpen: action.payload
      };
    }

    case getType(setSearchType): {
      return {
        ...state,
        searchType: action.payload
      };
    }

    case getType(setCustomSortingOptionsLoaded): {
      return { ...state, customSortingOptionsLoaded: action.payload };
    }

    case getType(setFilterCompletionQueryLoading): {
      return {
        ...state,
        filterCompletionQueryLoading: action.payload
      };
    }

    case getType(loadCustomSortingOptions.success):
    case getType(createCustomSortingOption.success):
    case getType(editCustomSortingOption.success):
    case getType(deleteCustomSortingOption.success): {
      return {
        ...state,
        customSortingOptions: action.payload
      };
    }

    case getType(setNameQuery): {
      return {
        ...state,
        nameQuery: action.payload
      };
    }
    case getType(setKeywordQuery): {
      return {
        ...state,
        keywordQuery: action.payload
      };
    }

    case getType(setInitialOptionsLoaded): {
      return {
        ...state,
        initialOptionsLoaded: action.payload
      };
    }

    case getType(setAdvancedFilterSectionsOpen): {
      return {
        ...state,
        advancedFiltersOpen: action.payload
      };
    }

    case getType(toggleAdvancedFilterSection): {
      let advancedFiltersOpen: AdvancedFilterSections[] = [];

      if (state.advancedFiltersOpen.indexOf(action.payload) !== -1) {
        advancedFiltersOpen = state.advancedFiltersOpen.filter(
          i => i !== action.payload
        );
      } else {
        advancedFiltersOpen = [...state.advancedFiltersOpen, action.payload];
      }

      return {
        ...state,
        advancedFiltersOpen
      };
    }

    case getType(clearSearchFilters): {
      let res: SearchFilterState = {
        ...searchFilterInitialState,
        showAdvancedFilters: state.showAdvancedFilters,
        initialOptions: state.initialOptions,
        initialOptionsLoaded: state.initialOptionsLoaded,
        advancedFiltersOpen: state.advancedFiltersOpen,
        nameQuery: state.nameQuery,
        keywordQuery: state.keywordQuery,
        searchType: state.searchType
        // };
      };

      // Prevent clearing advanced filters, as they're not rendered
      if (state.searchType === SearchTypes.NAME) {
        res = {
          ...res,
          publications: state.publications,
          trials: state.trials,
          congresses: state.congresses,
          grants: state.grants,
          payments: state.payments
        };
      }

      return res;
    }
    case getType(setShowAdvancedFilters): {
      return {
        ...state,
        showAdvancedFilters: action.payload
      };
    }
    case getType(getInitialFilterOptions.success): {
      return mapInitialSearchFilterOptionsToState(state, action.payload);
    }
    case getType(resetInitialOptions): {
      return mapInitialSearchFilterOptionsToState(state, state.initialOptions);
    }

    // Specialty
    case getType(setSpecialtyQuery): {
      return {
        ...state,
        specialty: {
          ...state.specialty,
          query: action.payload
        }
      };
    }
    case getType(setSpecialtyValues): {
      return {
        ...state,
        specialty: {
          ...state.specialty,
          values: action.payload
        }
      };
    }
    case getType(requestSpecialtyOptions.success): {
      return {
        ...state,
        specialty: {
          ...state.specialty,
          options: action.payload
        }
      };
    }

    // Country
    case getType(setCountryQuery): {
      return {
        ...state,
        country: {
          ...state.country,
          query: action.payload
        }
      };
    }
    case getType(setCountryValues): {
      return {
        ...state,
        country: {
          ...state.country,
          values: action.payload
        }
      };
    }
    case getType(requestCountryOptions.success): {
      return {
        ...state,
        country: {
          ...state.country,
          options: action.payload
        }
      };
    }

    // State
    case getType(setStateQuery): {
      return {
        ...state,
        state: {
          ...state.state,
          query: action.payload
        }
      };
    }
    case getType(setStateValues): {
      return {
        ...state,
        state: {
          ...state.state,
          values: action.payload
        }
      };
    }
    case getType(requestStateOptions.success): {
      return {
        ...state,
        state: {
          ...state.state,
          options: action.payload
        }
      };
    }

    // Institutions
    case getType(setInstitutionQuery): {
      return {
        ...state,
        institution: {
          ...state.institution,
          query: action.payload
        }
      };
    }
    case getType(setInstitutionValues): {
      return {
        ...state,
        institution: {
          ...state.institution,
          values: action.payload
        }
      };
    }
    case getType(requestInstitutionOptions.success): {
      return {
        ...state,
        institution: {
          ...state.institution,
          options: action.payload
        }
      };
    }
    case getType(setInstitutionTypeValues): {
      return {
        ...state,
        institutionType: {
          ...state.institutionType,
          values: action.payload
        }
      };
    }
    // Tags
    case getType(setTagNameQuery): {
      return {
        ...state,
        tags: {
          ...state.tags,
          name: {
            ...state.tags.name,
            query: action.payload
          }
        }
      };
    }
    case getType(setTagNameValues): {
      return {
        ...state,
        tags: {
          ...state.tags,
          name: {
            ...state.tags.name,
            values: action.payload
          }
        }
      };
    }
    case getType(requestTagNameOptions.success): {
      return {
        ...state,
        tags: {
          ...state.tags,
          name: {
            ...state.tags.name,
            options: action.payload
          }
        }
      };
    }
    // Publications
    case getType(setPublicationMinCount): {
      return {
        ...state,
        publications: {
          ...state.publications,
          minCount: {
            ...state.publications.minCount,
            value: action.payload
          }
        }
      };
    }
    case getType(setPublicationSocialMinCount): {
      return {
        ...state,
        publications: {
          ...state.publications,
          socialMediaMinCount: {
            ...state.publications.socialMediaMinCount,
            value: action.payload
          }
        }
      };
    }
    case getType(setPublicationJournalQuery): {
      return {
        ...state,
        publications: {
          ...state.publications,
          journal: {
            ...state.publications.journal,
            query: action.payload
          }
        }
      };
    }
    case getType(setPublicationJournalValues): {
      return {
        ...state,
        publications: {
          ...state.publications,
          journal: {
            ...state.publications.journal,
            values: action.payload
          }
        }
      };
    }
    case getType(requestPublicationJournalOptions.success): {
      return {
        ...state,
        publications: {
          ...state.publications,
          journal: {
            ...state.publications.journal,
            options: action.payload
          }
        }
      };
    }
    case getType(setPublicationTypeQuery): {
      return {
        ...state,
        publications: {
          ...state.publications,
          type: {
            ...state.publications.type,
            query: action.payload
          }
        }
      };
    }
    case getType(setPublicationTypeValues): {
      return {
        ...state,
        publications: {
          ...state.publications,
          type: {
            ...state.publications.type,
            values: action.payload
          }
        }
      };
    }
    case getType(requestPublicationTypeOptions.success): {
      return {
        ...state,
        publications: {
          ...state.publications,
          type: {
            ...state.publications.type,
            options: action.payload
          }
        }
      };
    }

    // Trials
    case getType(setTrialMinCount): {
      return {
        ...state,
        trials: {
          ...state.trials,
          minCount: {
            ...state.trials.minCount,
            value: action.payload
          }
        }
      };
    }
    case getType(setTrialStatusQuery): {
      return {
        ...state,
        trials: {
          ...state.trials,
          status: {
            ...state.trials.status,
            query: action.payload
          }
        }
      };
    }
    case getType(setTrialStatusValues): {
      return {
        ...state,
        trials: {
          ...state.trials,
          status: {
            ...state.trials.status,
            values: action.payload
          }
        }
      };
    }
    case getType(requestTrialStatusOptions.success): {
      return {
        ...state,
        trials: {
          ...state.trials,
          status: {
            ...state.trials.status,
            options: action.payload
          }
        }
      };
    }

    case getType(setTrialPhaseQuery): {
      return {
        ...state,
        trials: {
          ...state.trials,
          phase: {
            ...state.trials.phase,
            query: action.payload
          }
        }
      };
    }
    case getType(setTrialPhaseValues): {
      return {
        ...state,
        trials: {
          ...state.trials,
          phase: {
            ...state.trials.phase,
            values: action.payload
          }
        }
      };
    }
    case getType(requestTrialPhaseOptions.success): {
      return {
        ...state,
        trials: {
          ...state.trials,
          phase: {
            ...state.trials.phase,
            options: action.payload
          }
        }
      };
    }

    case getType(setTrialStudyTypeQuery): {
      return {
        ...state,
        trials: {
          ...state.trials,
          studyType: {
            ...state.trials.studyType,
            query: action.payload
          }
        }
      };
    }
    case getType(setTrialStudyTypeValues): {
      return {
        ...state,
        trials: {
          ...state.trials,
          studyType: {
            ...state.trials.studyType,
            values: action.payload
          }
        }
      };
    }
    case getType(requestTrialStudyTypeOptions.success): {
      return {
        ...state,
        trials: {
          ...state.trials,
          studyType: {
            ...state.trials.studyType,
            options: action.payload
          }
        }
      };
    }

    case getType(setTrialFunderTypeQuery): {
      return {
        ...state,
        trials: {
          ...state.trials,
          funderType: {
            ...state.trials.funderType,
            query: action.payload
          }
        }
      };
    }
    case getType(setTrialFunderTypeValues): {
      return {
        ...state,
        trials: {
          ...state.trials,
          funderType: {
            ...state.trials.funderType,
            values: action.payload
          }
        }
      };
    }
    case getType(requestTrialFunderTypeOptions.success): {
      return {
        ...state,
        trials: {
          ...state.trials,
          funderType: {
            ...state.trials.funderType,
            options: action.payload
          }
        }
      };
    }

    case getType(setTrialSponsorQuery): {
      return {
        ...state,
        trials: {
          ...state.trials,
          sponsor: {
            ...state.trials.sponsor,
            query: action.payload
          }
        }
      };
    }
    case getType(setTrialSponsorValues): {
      return {
        ...state,
        trials: {
          ...state.trials,
          sponsor: {
            ...state.trials.sponsor,
            values: action.payload
          }
        }
      };
    }
    case getType(requestTrialSponsorOptions.success): {
      return {
        ...state,
        trials: {
          ...state.trials,
          sponsor: {
            ...state.trials.sponsor,
            options: action.payload
          }
        }
      };
    }

    // Congresses
    case getType(setCongressMinCount): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          minCount: {
            ...state.congresses.minCount,
            value: action.payload
          }
        }
      };
    }

    case getType(setCongressNameQuery): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          name: {
            ...state.congresses.name,
            query: action.payload
          }
        }
      };
    }
    case getType(setCongressNameValues): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          name: {
            ...state.congresses.name,
            values: action.payload
          }
        }
      };
    }
    case getType(requestCongressNameOptions.success): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          name: {
            ...state.congresses.name,
            options: action.payload
          }
        }
      };
    }

    case getType(setCongressOrganizerNameQuery): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          organizerName: {
            ...state.congresses.organizerName,
            query: action.payload
          }
        }
      };
    }
    case getType(setCongressOrganizerNameValues): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          organizerName: {
            ...state.congresses.organizerName,
            values: action.payload
          }
        }
      };
    }
    case getType(requestCongressOrganizerNameOptions.success): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          organizerName: {
            ...state.congresses.organizerName,
            options: action.payload
          }
        }
      };
    }

    case getType(setCongressSessionTypeQuery): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          sessionType: {
            ...state.congresses.sessionType,
            query: action.payload
          }
        }
      };
    }
    case getType(setCongressSessionTypeValues): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          sessionType: {
            ...state.congresses.sessionType,
            values: action.payload
          }
        }
      };
    }

    case getType(requestCongressSessionTypeOptions.success): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          sessionType: {
            ...state.congresses.sessionType,
            options: action.payload
          }
        }
      };
    }

    case getType(setCongressTypeValues): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          type: {
            ...state.congresses.type,
            values: action.payload
          }
        }
      };
    }
    case getType(requestCongressTypeOptions.success): {
      return {
        ...state,
        congresses: {
          ...state.congresses,
          type: {
            ...state.congresses.type,
            options: action.payload
          }
        }
      };
    }

    // Grants
    case getType(setGrantMinAmount): {
      return {
        ...state,
        grants: {
          ...state.grants,
          minAmount: {
            ...state.grants.minAmount,
            value: action.payload
          }
        }
      };
    }

    case getType(setGrantFunderQuery): {
      return {
        ...state,
        grants: {
          ...state.grants,
          funder: {
            ...state.grants.funder,
            query: action.payload
          }
        }
      };
    }
    case getType(setGrantFunderValues): {
      return {
        ...state,
        grants: {
          ...state.grants,
          funder: {
            ...state.grants.funder,
            values: action.payload
          }
        }
      };
    }
    case getType(requestGrantFunderOptions.success): {
      return {
        ...state,
        grants: {
          ...state.grants,
          funder: {
            ...state.grants.funder,
            options: action.payload
          }
        }
      };
    }

    // Payments
    case getType(setPaymentMinAmount): {
      return {
        ...state,
        payments: {
          ...state.payments,
          minAmount: {
            ...state.payments.minAmount,
            value: action.payload
          }
        }
      };
    }

    case getType(setPaymentCompanyQuery): {
      return {
        ...state,
        payments: {
          ...state.payments,
          company: {
            ...state.payments.company,
            query: action.payload
          }
        }
      };
    }
    case getType(setPaymentCompanyValues): {
      return {
        ...state,
        payments: {
          ...state.payments,
          company: {
            ...state.payments.company,
            values: action.payload
          }
        }
      };
    }
    case getType(requestPaymentCompanyOptions.success): {
      return {
        ...state,
        payments: {
          ...state.payments,
          company: {
            ...state.payments.company,
            options: action.payload
          }
        }
      };
    }

    case getType(setPaymentDrugOrDeviceQuery): {
      return {
        ...state,
        payments: {
          ...state.payments,
          drugOrDevice: {
            ...state.payments.drugOrDevice,
            query: action.payload
          }
        }
      };
    }
    case getType(setPaymentDrugOrDeviceValues): {
      return {
        ...state,
        payments: {
          ...state.payments,
          drugOrDevice: {
            ...state.payments.drugOrDevice,
            values: action.payload
          }
        }
      };
    }
    case getType(requestPaymentDrugOrDeviceOptions.success): {
      return {
        ...state,
        payments: {
          ...state.payments,
          drugOrDevice: {
            ...state.payments.drugOrDevice,
            options: action.payload
          }
        }
      };
    }

    case getType(setPaymentFundingTypeQuery): {
      return {
        ...state,
        payments: {
          ...state.payments,
          fundingType: {
            ...state.payments.fundingType,
            query: action.payload
          }
        }
      };
    }
    case getType(setPaymentFundingTypeValues): {
      return {
        ...state,
        payments: {
          ...state.payments,
          fundingType: {
            ...state.payments.fundingType,
            values: action.payload
          }
        }
      };
    }
    case getType(requestPaymentFundingTypeOptions.success): {
      return {
        ...state,
        payments: {
          ...state.payments,
          fundingType: {
            ...state.payments.fundingType,
            options: action.payload
          }
        }
      };
    }
    case getType(setFilterOptionLoading): {
      return {
        ...state,
        filterOptionsKeysLoading: [
          ...state.filterOptionsKeysLoading,
          action.payload
        ]
      };
    }

    case getType(clearFilterOptionLoading): {
      return {
        ...state,
        filterOptionsKeysLoading: state.filterOptionsKeysLoading.filter(
          i => !action.payload.includes(i)
        )
      };
    }
  }

  return state;
};
