/* tslint:disable:forin */
import { TagInterface } from "@h1eng/interfaces";
import { isEqual, sortBy } from "lodash";
import { of, empty } from "rxjs";
import {
  searchElastic,
  setProfileTags,
  updateListCardsWithoutSideEffects
} from "../actions";
import { RootState } from "../reducers";

export interface PersonIdAndTags {
  personId: string;
  tags: TagInterface[];
}

export interface TagsPayload {
  [personId: string]: TagInterface[];
}

type TagsResponse = PersonIdAndTags | TagsPayload;

export function getTagsPayload(tags: TagsResponse): TagsPayload {
  if (tags.personId && tags.tags && typeof tags.personId === "string") {
    return {
      [tags.personId]: tags.tags
    };
  }

  // Typescript is giving an error, but I've confirmed the logic in ./tagEpicHelpers.test.ts
  // @ts-ignore
  return tags;
}

export const updateTagsForPerson = <
  T extends { personId: string; tags: TagInterface[] }
>(
  people: T[],
  tags: TagsPayload,
  personId: string
): T[] => {
  const match = people.find(i => i.personId === personId);
  const newTags = tags[personId];
  if (!match || !newTags) return people;

  const res = people.map(i => {
    if (i.personId !== personId) return i;

    return {
      ...i,
      tags: newTags
    };
  });

  // res[res.indexOf(match)].tags = newTags;

  return res;
};

/**
 * Determine whether or not results have been updated, so Rxjs.empty() can be returned
 */
export const resultsMatch = (arr1: any[], arr2: any[]) =>
  isEqual(sortBy(arr1), sortBy(arr2));

export const updateTagsInSearchResults = (
  tagsResponse: TagsResponse,
  state: RootState
) => {
  let results = [...state.searchResults.results];
  const tags = getTagsPayload(tagsResponse);

  for (const personId in tags) {
    results = updateTagsForPerson(results, tags, personId);
  }

  return resultsMatch(state.searchResults.results, results)
    ? empty()
    : of(
        searchElastic.success({
          ...state.searchResults,
          results
        })
      );
};

export const updateTagsOnProfile = (
  tagsResponse: TagsResponse,
  state: RootState
) => {
  const { personId } = state.documentSearchBar;
  if (!personId) return empty();

  const tags = getTagsPayload(tagsResponse);

  if (tags[personId]) {
    return of(setProfileTags.success({ personId, tags: tags[personId] }));
  }

  return empty();
};

export const updateTagsInSavedLists = (
  tagsResponse: TagsResponse,
  state: RootState
) => {
  const currentLists = state.lists.currentLists;
  if (!currentLists || !currentLists.length) return empty();

  const tags = getTagsPayload(tagsResponse);

  const payload = currentLists.map(i => {
    const newTags = tags[i.personId];
    if (!newTags) return i;

    return {
      ...i,
      tags: newTags
    };
  });

  return resultsMatch(currentLists, payload)
    ? empty()
    : of(updateListCardsWithoutSideEffects(payload));
};
