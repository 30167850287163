import { connect } from "react-redux";
import { SEARCH_RESULT_LIMIT } from "@h1eng/interfaces";
import { RootState } from "../../../store/reducers";
import { getPageNum, getPageSize } from "../../../store/selectors";
import { userSetPageNum } from "../../../store/actions/userInteractionActions";
import { Pagination as P } from "../common/Pagination";

const mapStateToProps = (state: RootState) => ({
  pageNum: getPageNum(state),
  pageSize: getPageSize(state),
  total: Math.min(state.searchResults.total, SEARCH_RESULT_LIMIT)
});

const mapDispatchToProps = {
  loadPage: userSetPageNum
};

export const Pagination = connect(
  mapStateToProps,
  mapDispatchToProps
)(P);
