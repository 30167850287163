/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { Card } from "../../../common/Card";
import { PersonCard, SearchView } from "@h1eng/interfaces";
import { styled } from "@h1eng/ui-components";
import { ResultCardContent } from "./ResultCardContent";
import { Checkbox } from "../../../common/Checkbox";
import { ResultPercentageIndicators } from "./ResultPercentageIndicators";
import { WorkOverTime } from "./WorkOverTime/WorkOverTime";
import { WorksLink } from "../../../SavedListsPage/SavedListCards/WorksLink";
import { formatStats } from "@h1eng/format-util";
import { RequestKOLDataButton } from "./RequestKOLDataButton";
import {
  getTotalWorksForKOL,
  kolCanRequestInfo
} from "../../../../../lib/getTotalWorksForKOL";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  margin-left: -24px;
`;
const WorksRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
`;

interface Props {
  selected: boolean;
  lists: any[];
  id: string;
  selectedListId: string | null;
  listsAvailable: any[];
  onSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  person: PersonCard;
  listButton: React.ReactElement<any>;
  searchView: SearchView;
}

export class ResultCard extends React.Component<Props> {
  get workView() {
    const { person, searchView } = this.props;

    const canRequestInfo = kolCanRequestInfo(person);

    const requestInfo = (
      <RequestKOLDataButton
        personId={person.personId}
        kolDataRequests={person.kolDataRequests}
      />
    );

    let workView = <>hi</>;

    if (searchView === SearchView.SearchedWork) {
      workView = (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {canRequestInfo && (
            <div
              style={{
                marginBottom: 20,
                display: "flex",
                alignSelf: "flex-end",
                width: "100%"
              }}
            >
              {requestInfo}
            </div>
          )}
          <ResultPercentageIndicators
            scoredDocuments={person.scores}
            personId={person.personId}
          />
        </div>
      );
    }
    if (searchView === SearchView.TotalWork) {
      workView = (
        <WorksRoot>
          {canRequestInfo && (
            <div
              style={{
                marginBottom: 20,
                display: "flex",
                alignSelf: "flex-end",
                width: "100%"
              }}
            >
              {requestInfo}
            </div>
          )}
          {person.countPublications > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/publications`}
              label="Publications"
              value={formatStats(person.countPublications)}
              color="publications"
            />
          )}
          {person.citationCount > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/publications`}
              label="Citations"
              value={formatStats(person.citationCount)}
              color="citations"
            />
          )}
          {person.socialMediaMentionsTotal > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/publications`}
              label="Social Media Mentions"
              value={formatStats(person.socialMediaMentionsTotal)}
              color="socialMediaMentions"
            />
          )}
          {person.countClinicalTrials > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/clinical-trials`}
              label="Clinical Trials"
              value={formatStats(person.countClinicalTrials)}
              color="trials"
            />
          )}
          {person.congresses > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/congresses`}
              label="Congresses"
              value={formatStats(person.congresses)}
              color="congresses"
            />
          )}
          {person.sumPayments > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/payments`}
              label="Payments"
              value={`$${formatStats(person.sumPayments)}`}
              color="payments"
            />
          )}
          {person.sumGrants > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/grants`}
              label="Grants"
              value={`$${formatStats(person.sumGrants)}`}
              color="grants"
            />
          )}
        </WorksRoot>
      );
    }
    if (searchView === SearchView.WorkOverTime) {
      workView = canRequestInfo ? (
        requestInfo
      ) : (
          <WorkOverTime
            dates={[
              ...person.congressesDates,
              ...person.publicationDates,
              ...person.trailDates
            ]}
          />
        );
    }

    return workView;
  }

  render() {
    const { person, selected, onSelect, listButton, id } = this.props;

    return (
      <Card>
        <Root>
          <CheckboxWrapper>
            <Checkbox
              value={person.personId}
              onChange={onSelect}
              checked={selected}
              color="primary"
            />
          </CheckboxWrapper>
          <ResultCardContent id={id} person={person} listButton={listButton} />
          {this.workView}
        </Root>
      </Card>
    );
  }
}
