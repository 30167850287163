import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const getCollaboratorState = (state: RootState) => state.collaborator;

export const getFullState = (state: RootState) => state;

export const getPersonCollaborationsDocIds = createSelector(
  getCollaboratorState,
  collaborator => collaborator.collaborators
);

export const getCollaborators = createSelector(
  getCollaboratorState,
  collaborator => collaborator.collaborators
);

export const getLiteProfiles = createSelector(
  getCollaboratorState,
  collaborator => collaborator.liteProfiles
);

export const getPage = createSelector(
  getCollaboratorState,
  collaborator => collaborator.page
);

export const getSize = createSelector(
  getCollaboratorState,
  collaborator => collaborator.size
);

export const getSortedIds = createSelector(
  getCollaboratorState,
  collaborator => collaborator.collaborators.map(e => e.personId)
);

export const getLoading = createSelector(
  getCollaboratorState,
  collaborator =>
    collaborator.loading ||
    collaborator.loadingDetails ||
    collaborator.loadingLiteProfile
);
export const getStart = createSelector(
  getCollaboratorState,
  collaborator => {
    const { page, size } = collaborator;
    return page * size + 1;
  }
);

export const getElasticSearchDate = createSelector(
  getFullState,
  state => {
    if (state.documentSearchBar.filterDateText === "All Time") {
      return undefined;
    }
    return Math.floor(state.documentSearchBar.filterDate / 1000);
  }
);
export const getEnd = createSelector(
  getCollaboratorState,
  collaborator => {
    return collaborator.collaborators.length;
    // const sortedIds = ids.sort(
    //   (a, b) => a.count - b.count
    // );
    // const { size } = collaborator;
    // const ordered = sortedIds.length;
    // if (ordered < size) {
    //   return sortedIds.length;
    // }
    // return size;
  }
);
export const getPersonId = createSelector(
  getCollaboratorState,
  collaborartor => {
    return collaborartor.personId;
  }
);

export const getCurrentIds = createSelector(
  getCollaboratorState,
  collaborator => {
    // const ids =
    // Object.assign(collaborator.collaborators, []);
    // console.log("ids", JSON.stringify(ids));
    // const sortedIds = ids.sort(
    //   (a, b) => a.count - b.count
    // );
    const sortedIds = collaborator.collaborators.map(e => e.personId);
    const { page, size } = collaborator;
    const start = page * size;
    const ordered = sortedIds;
    const nszie = page * size + size;
    let end;

    if (ordered.length < nszie) {
      end = ordered.length;
    } else {
      end = nszie;
    }

    const clonedArray = JSON.parse(JSON.stringify(sortedIds));
    return clonedArray.slice(start, end);
  }
);
