import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/reducers";
import { getCurrentListCards } from "../../../store/actions/lists";

export default () => {
  const personId = useSelector((state: RootState) => state.lists.selectedId);
  const pageNum = useSelector(
    (state: RootState) => state.lists.savedListPageNum
  );
  const pageSize = useSelector(
    (state: RootState) => state.lists.savedListPageSize
  );
  const lists = useSelector((state: RootState) => state.lists.lists);

  const dispatch = useDispatch();

  const fetchLists = React.useCallback(
    () => dispatch(getCurrentListCards.request()),
    [dispatch]
  );

  const cancelFetchLists = React.useCallback(
    () => dispatch(getCurrentListCards.cancel()),
    [dispatch]
  );

  React.useEffect(() => {
    if (personId) {
      fetchLists();
    }

    return () => {
      cancelFetchLists();
    };
  }, [fetchLists, cancelFetchLists, personId, pageNum, pageSize, lists]);

  return null;
};
