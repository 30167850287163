import * as React from "react";
import { connect } from "react-redux";
import { styled, css, H3, H4 } from "@h1eng/ui-components";
import { RootState } from "../../../store/reducers";
import { Card } from "../common/Card";
import TextMetrics from "./TextMetrics";






const Grid = styled.div`
  background: white;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
`;

const MetricsHeader = styled.div`
display: flex;
justify-content: center;
padding-top: 10px;`;
'padding-bottom: 10px;'

interface MappedStateProps {
  stats: { stats: { count: number, value: string }[], title: string }[];
}

interface DispatchProps {

}

type Props = MappedStateProps & DispatchProps



const StatsViewrRender: React.FunctionComponent<Props> = ({
  stats,
  ...props
}) => {


  return (
    <Card>
      <MetricsHeader><H3>Metrics</H3></MetricsHeader>
      <Grid>
        <TextMetrics metrics={stats} />
      </Grid>
    </Card>

  );
};

export const mapStateToProps = (state: RootState): MappedStateProps => {
  // @ts-ignore
  const stats = state.searchResults.results as { stats: { count: number, value: string }[], title: string }[];

  return {
    stats,
  };
};

const mapDispatchToProps: DispatchProps = {

};

export const StatsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatsViewrRender);
