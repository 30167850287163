import {
  createAsyncAction,
  ActionType,
  createStandardAction
} from "typesafe-actions";
import {
  SlackKOLRequestPayload,
  SlackKOLRequestPayloadValidationErrors,
  KOLDataRequest,
  PersonKOLDataRequests,
  UserNotFoundError
} from "@h1eng/interfaces";

// Request KOL
export const requestKol = createAsyncAction(
  "userInfoRequest.requestKol.request",
  "userInfoRequest.requestKol.success",
  "userInfoRequest.requestKol.failure"
)<
  SlackKOLRequestPayload,
  { errors: false | SlackKOLRequestPayloadValidationErrors },
  Error
>();

export const setRequestedKolLoading = createStandardAction(
  "userInfoRequest.requestedKol.loading.set"
)<boolean>();

export const setRequestedKolData = createStandardAction(
  "userInfoRequest.requestedKol.kol.data.set"
)<SlackKOLRequestPayload>();

export const setRequestedKolErrors = createStandardAction(
  "userInfoRequest.requestedKol.errors.set"
)<SlackKOLRequestPayloadValidationErrors | false>();

export const setRequestedKolModalOpen = createStandardAction(
  "userInfoRequest.requestedKol.modal.setOpen"
)<boolean>();

export const resetRequestedKolData = createStandardAction(
  "userInfoRequest.requestedKol.kol.data.reset"
)<never>();

export const setRequestedKolFormStarted = createStandardAction(
  "userInfoRequest.requestedKol.kol.data.form.started"
)<boolean>();

// Request KOL data
export const setKolDataRequestPersonId = createStandardAction(
  "userInfoRequest.requestKolData.personId.set"
)<string | null>();

export const requestKolData = createAsyncAction(
  "userInfoRequest.requestKolData.fetch.request",
  "userInfoRequest.requestKolData.fetch.success",
  "userInfoRequest.requestKolData.fetch.failure"
)<string, KOLDataRequest, Error | UserNotFoundError>();

export const appendKOLDataRequestToCard = createStandardAction(
  "userInfoRequest.appendRequestToSearchResults"
)<KOLDataRequest>();

export const fetchSelectedPersonRequests = createAsyncAction(
  "userInfoRequest.requestKolData.selectedPersonRequests.fetch.request",
  "userInfoRequest.requestKolData.selectedPersonRequests.fetch.success",
  "userInfoRequest.requestKolData.selectedPersonRequests.fetch.failure"
)<string, PersonKOLDataRequests, Error>();

export const setRequestKolDataModalOpen = createStandardAction(
  "userInfoRequest.requestKolData.modal.setOpen"
)<boolean>();

/**
 * Set the loading state of user requests for a person
 */
export const setPeopleRequestsLoading = createStandardAction(
  "userInfoRequest.requestKolData.request.setLoading"
)<boolean>();

export type UserInfoRequestAction =
  | ActionType<typeof setRequestedKolLoading>
  | ActionType<typeof requestKol>
  | ActionType<typeof setRequestedKolData>
  | ActionType<typeof setRequestedKolErrors>
  | ActionType<typeof setRequestedKolModalOpen>
  | ActionType<typeof setKolDataRequestPersonId>
  | ActionType<typeof requestKolData>
  | ActionType<typeof fetchSelectedPersonRequests>
  | ActionType<typeof setRequestKolDataModalOpen>
  | ActionType<typeof setPeopleRequestsLoading>
  | ActionType<typeof appendKOLDataRequestToCard>;
