import {
  requestKol,
  resetRequestedKolData,
  setRequestedKolErrors,
  setRequestedKolLoading,
  setRequestedKolModalOpen,
  setKolDataRequestPersonId,
  requestKolData,
  setRequestKolDataModalOpen,
  setPeopleRequestsLoading,
  fetchSelectedPersonRequests
} from "../actions/userInfoRequest";
import { ActionType, isActionOf } from "typesafe-actions";
import {
  createGrowler,
  appendKOLDataRequestToCard,
  updateSearchResults
} from "../actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  switchMap,
  map,
  catchError,
  filter,
  withLatestFrom
} from "rxjs/operators";
import { from, of, pipe, concat, empty } from "rxjs";
import {
  createKolRequest,
  createKolDataRequest,
  fetchRequestsForPerson
} from "../../apis/requestKol";
import { RootState } from "../reducers";
import {
  GrowlerTypes,
  SlackKOLRequestPayload,
  SlackKOLRequestPayloadValidationErrors,
  KOLDataRequest,
  UserNotFoundError
} from "@h1eng/interfaces";

const requestKOLFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(requestKol.request),
    switchMap(({ payload }: { payload: SlackKOLRequestPayload }) =>
      concat(
        of(setRequestedKolLoading(true)),
        from(createKolRequest(payload)).pipe(
          map(requestKol.success),
          catchError(
            pipe(
              requestKol.failure,
              of
            )
          )
        )
      )
    )
  );

const appendKOLDataRequestToCardEpic = (
  action$: ActionsObservable<ActionType<typeof appendKOLDataRequestToCard>>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(appendKOLDataRequestToCard)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { results } = state.searchResults;

      const res = results.map(i => {
        if (i.personId === payload.personId) {
          return {
            ...i,
            kolDataRequests: [...i.kolDataRequests, payload]
          };
        }
        return i;
      });

      return of(updateSearchResults(res));
    })
  );

const requestKOLSuccessFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>
) =>
  action$.pipe(
    ofType(requestKol.success),
    switchMap(
      ({
        payload
      }: {
        payload: { errors: false | SlackKOLRequestPayloadValidationErrors };
      }) =>
        payload.errors === false
          ? concat(
              of(setRequestedKolLoading(false)),
              of(setRequestedKolModalOpen(false)),
              of(setRequestedKolErrors(false)),
              of(resetRequestedKolData()),
              of(
                createGrowler({
                  title: "New Individual Requested",
                  description:
                    "Information about this individual has been requested & you will receive an email update when that information is available.",
                  titleIcon: "check",
                  growler: GrowlerTypes.success
                })
              )
            )
          : concat(
              of(setRequestedKolErrors(payload.errors)),
              of(setRequestedKolLoading(false))
            )
    )
  );

const requestKOLFailureFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>
) =>
  action$.pipe(
    ofType(requestKol.failure),
    switchMap(() =>
      concat(
        of(setRequestedKolLoading(false)),
        of(
          createGrowler({
            title: "Error",
            description: "There was an error making your request",
            titleIcon: "warning",
            growler: GrowlerTypes.fail
          })
        )
      )
    )
  );

const resetDataOnModalToggleFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>
) =>
  action$.pipe(
    ofType(setRequestedKolModalOpen),
    switchMap(() =>
      concat(
        of(resetRequestedKolData()),
        of(setRequestedKolErrors(false)),
        of(setRequestedKolLoading(false))
      )
    )
  );

// Request KOL Data
const handleRequestKOLDataRequestFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(requestKolData.request),
    switchMap(({ payload }: { payload: string }) =>
      concat(
        from(createKolDataRequest(payload)).pipe(
          map(requestKolData.success),
          catchError(
            pipe(
              requestKolData.failure,
              of
            )
          )
        )
      )
    )
  );

const handleRequestKOLDataSuccessFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(requestKolData.success),
    switchMap(({ payload }: { payload: KOLDataRequest }) =>
      concat(
        state$.value.userInfoRequest.requestKolData.personId ===
          payload.personId
          ? of(
              fetchSelectedPersonRequests.request(
                state$.value.userInfoRequest.requestKolData.personId
              )
            )
          : empty(),
        of(setRequestKolDataModalOpen(false)),
        of(
          createGrowler({
            title: "Profile Information Requested",
            description:
              "Information about this person has been requested & you will receive an email update when that information is available.",
            titleIcon: "check",
            growler: GrowlerTypes.success
          })
        ),
        of(appendKOLDataRequestToCard(payload))
      )
    )
  );

const handleRequestKOLDataFailureFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(requestKolData.failure),
    switchMap(({ payload }: { payload: Error | UserNotFoundError }) =>
      payload instanceof UserNotFoundError
        ? concat(
            of(
              createGrowler({
                title: "Information Already Requested",
                description:
                  "A user has already requested data on this person.",
                titleIcon: "warning",
                growler: GrowlerTypes.fail
              })
            ),
            of(setRequestKolDataModalOpen(false))
          )
        : concat(
            of(
              createGrowler({
                title: "Error",
                description: "There was an error making your request",
                titleIcon: "warning",
                growler: GrowlerTypes.fail
              })
            ),
            of(setRequestKolDataModalOpen(false))
          )
    )
  );

// const handleSetSelectedIdFlow: Epic<any, any, any> = (
//   action$: ActionsObservable<any>,
//   state$: StateObservable<RootState>
// ) =>
//   action$.pipe(
//     ofType(setKolDataRequestPersonId),
//     switchMap(({ payload }: { payload: string | null }) => {
//       const {
//         selectedPersonRequests,
//         personId
//       } = state$.value.userInfoRequest.requestKolData;

//       if (payload === null) {
//         return empty(); //of(fetchSelectedPersonRequests.success([]));

//         // No need to refetch if the id hasn't changed
//       } else if (
//         payload === personId &&
//         selectedPersonRequests.length > 0 &&
//         selectedPersonRequests.every(i => i.personId === payload)
//       ) {
//         return empty();
//       }
//       return of(fetchSelectedPersonRequests.request(payload));
//     })
//   );

const handleFetchRequestsForSelectedIdFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(fetchSelectedPersonRequests.request),
    switchMap(({ payload }: { payload: string | null }) =>
      payload !== null
        ? concat(
            of(setPeopleRequestsLoading(true)),
            from(fetchRequestsForPerson(payload)).pipe(
              map(fetchSelectedPersonRequests.success),
              catchError(
                pipe(
                  fetchSelectedPersonRequests.failure,
                  of
                )
              )
            )
          )
        : empty()
    )
  );

const handleFetchPeopleRequestsSuccessFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(fetchSelectedPersonRequests.success),
    switchMap(() => of(setPeopleRequestsLoading(false)))
  );

const handleFetchPeopleRequestsFailureFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(fetchSelectedPersonRequests.failure),
    switchMap(() =>
      concat(
        of(setPeopleRequestsLoading(false)),
        of(
          createGrowler({
            title: "Error",
            description:
              "There was an error fetching requests for an individual",
            titleIcon: "warning",
            growler: GrowlerTypes.fail
          })
        )
      )
    )
  );

const userInfoRequestEpics = [
  requestKOLFlow,
  requestKOLSuccessFlow,
  requestKOLFailureFlow,
  resetDataOnModalToggleFlow,
  handleRequestKOLDataRequestFlow,
  handleRequestKOLDataSuccessFlow,
  handleRequestKOLDataFailureFlow,
  // handleSetSelectedIdFlow,
  handleFetchRequestsForSelectedIdFlow,
  handleFetchPeopleRequestsSuccessFlow,
  handleFetchPeopleRequestsFailureFlow,
  appendKOLDataRequestToCardEpic
];

export default userInfoRequestEpics;
